
import Vue from 'vue';
import RplLink from '@dpc-sdp/ripple-link';
import { RowViewModel, UrlColumn } from '@/models/table.model';
import { columnValue } from '@/helpers/table';
import _template from 'lodash/template';

type Data = {};

type Computed = { path?: string; text: string };

type Methods = { onClick: (event: MouseEvent) => void };

type Props = {
  rowViewModel: RowViewModel;
  viewPath: (row: RowViewModel) => string;
  column: UrlColumn;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SimpleLinkCell',
  components: { RplLink },
  props: { rowViewModel: Object, viewPath: Function, column: Object },
  computed: {
    path() {
      return _template(this.column.url)({
        rowId: this.rowViewModel.row.id,
        query: this.$route.query,
      });
    },
    text() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
  methods: {
    onClick(event) {
      if (this.path?.startsWith('/')) {
        event.preventDefault();
        this.$router.push(this.path);
      }
    },
  },
});
