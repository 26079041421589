
import Vue from 'vue';
import { AnchorPoint } from '@/models/site.model';

type Data = {
  open: boolean;
  popupAnchorPoint: AnchorPoint;
};

type Computed = {};

type Methods = {
  onToggle: (evt: MouseEvent) => void;
  onEsc: () => void;
  close: (evt: Event) => void;
};

type Props = { dynamicPosition?: boolean };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Popup',
  props: {
    dynamicPosition: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
      popupAnchorPoint: { x: 'right', y: 'top' },
    };
  },
  methods: {
    onToggle(evt) {
      if (this.dynamicPosition && !this.open) {
        this.popupAnchorPoint.x =
          window.innerWidth / 2 > evt.clientX ? 'left' : 'right';
        this.popupAnchorPoint.y =
          window.innerHeight / 2 > evt.clientY ? 'top' : 'bottom';
      }
      this.open = !this.open;
      if (this.open) {
        document.addEventListener('click', this.close);
      } else {
        document.removeEventListener('click', this.close);
      }
    },
    onEsc() {
      this.open = false;
      document.removeEventListener('click', this.close);
    },
    close(evt) {
      const container = this.$refs.container as Element;
      if (container && !container.contains(evt.target as Node)) {
        this.open = false;
        document.removeEventListener('click', this.close);
      }
    },
  },
});
