
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import LinkCell from '@/components/table/LinkCell.vue';
import SimpleLinkCell from '@/components/table/SimpleLinkCell.vue';
import WordWrappingCell from '@/components/table/WordWrappingCell.vue';

Vue.component('link-cell', LinkCell);
Vue.component('simple-link-cell', SimpleLinkCell);
Vue.component('word-wrapping-cell', WordWrappingCell);

type Data = {};

type Computed = {};

type Methods = {};

type Props = {
  rowViewModel: RowViewModel;
  viewPath: (row: RowViewModel) => string;
  column: Column;
  component: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TableComponent',
  components: {},
  props: {
    rowViewModel: Object,
    viewPath: Function,
    column: Object,
    component: String,
  },
});
