import moment from 'moment';
import {
  Column,
  ColumnData,
  ColumnDataType,
  FilterModel,
  Row,
  RowViewModel,
  SortDirection,
  SortOption,
  TableMetadata,
  TableViewModel,
} from '@/models/table.model';
import {
  formatDate,
  formatDateOrDateTime,
  isBetween,
  isSameOrAfter,
  isSameOrBefore,
} from '@/helpers/date';

const compareValuesByDataType = (
  value1: string,
  value2: string,
  dataType: keyof typeof ColumnDataType = ColumnDataType.Text,
) => {
  switch (dataType) {
    case ColumnDataType.Date:
    case ColumnDataType.DateTime: {
      const d1 = moment(value1);
      const d2 = moment(value2);
      if (!d1.isValid() && d2.isValid()) {
        return 1;
      }
      if (d1.isValid() && !d2.isValid()) {
        return -1;
      }
      if (!d1.isValid() && !d2.isValid()) {
        return 0;
      }
      return d1.valueOf() - d2.valueOf();
    }
    case ColumnDataType.FileSize:
    case ColumnDataType.Number: {
      const n1 = Number.parseFloat(value1);
      const n2 = Number.parseFloat(value2);
      if (Number.isNaN(n1) && !Number.isNaN(n2)) {
        return 1;
      }
      if (!Number.isNaN(n1) && Number.isNaN(n2)) {
        return -1;
      }
      if (Number.isNaN(n1) && Number.isNaN(n2)) {
        return 0;
      }
      return n1 - n2;
    }
    case ColumnDataType.Text:
    default: {
      return value1
        .toString()
        .toLowerCase()
        .localeCompare(value2.toString().toLocaleLowerCase());
    }
  }
};

const sortDirection = (sortOption: SortOption, value: number) =>
  sortOption.direction === SortDirection.Asc ? value : -value;

export const columnValue = (
  columnData: ColumnData[],
  columnKey: string,
): string | undefined =>
  columnData.find((colData) => colData.key === columnKey)?.value;

export const getColumnValue = <T extends string>(
  columnData: ColumnData[],
  columnKey: string,
): T => {
  const data = columnData.find((colData) => colData.key === columnKey);
  if (data && data.value) {
    return data.value as T;
  }
  throw Error(`Column value not found for column with key ${columnKey}`);
};

export const columnActions = (
  columnData: ColumnData[],
  columnKey: string,
): string[] =>
  columnData.find((colData) => colData.key === columnKey)?.actions || [];

export const columnComponent = (
  columnData: ColumnData[],
  columnKey: string,
): string | undefined =>
  columnData.find((colData) => colData.key === columnKey)?.component;

export const columnData = (
  colData: ColumnData[],
  columnKey: string,
): ColumnData | undefined => colData.find((cd) => cd.key === columnKey);

const sortItems = (
  i1: Row,
  i2: Row,
  sortOption: SortOption,
  column: Column,
) => {
  const value1 = columnValue(i1.columns, sortOption.columnKey);
  const value2 = columnValue(i2.columns, sortOption.columnKey);
  if (value1 === value2) {
    return 0;
  }
  if (!value2 || value2 === '') {
    return -1;
  }
  if (!value1 || value1 === '') {
    return 1;
  }

  return sortDirection(
    sortOption,
    compareValuesByDataType(
      value1.toString(),
      value2.toString(),
      column.dataType,
    ),
  );
};

export const sortRows = (
  items: RowViewModel[],
  sortOption: SortOption,
  column: Column,
) => items.sort((i1, i2) => sortItems(i1.row, i2.row, sortOption, column));

export const getSortOption = (
  sortOptions: SortOption[] | undefined,
  sortOptionId: string,
): SortOption | undefined =>
  sortOptions?.find(({ id }: SortOption) => id === sortOptionId);

export const getColumn = (columns: Column[], columnKey: string) => {
  const column = columns.find((col) => col.key === columnKey);
  if (!column) {
    throw new TypeError(`Column with key ${columnKey} not found`);
  }
  return column;
};

export const findColumn = (columns: Column[], columnKey: string) =>
  columns.find((col) => col.key === columnKey);

const sortOptionId = (columnKey: string, sortDir: keyof typeof SortDirection) =>
  `${columnKey}${sortDir}`;

export const sortDescription = (
  type: keyof typeof ColumnDataType,
  direction: keyof typeof SortDirection,
): string => {
  if (direction === SortDirection.Asc) {
    switch (type) {
      case 'Date':
      case 'DateTime':
        return 'oldest ➝ newest';
      case 'Number':
        return 'smallest ➝ largest';
      case 'Text':
      default:
        return 'a ➝ z';
    }
  } else {
    switch (type) {
      case 'Date':
      case 'DateTime':
        return 'newest ➝ oldest';
      case 'Number':
        return 'largest ➝ smallest';
      case 'Text':
      default:
        return 'z ➝ a';
    }
  }
};

export const buildSortOption = (
  col: Column,
  sortDir: keyof typeof SortDirection,
) => ({
  name: `${col.label} (${sortDescription(col.dataType, sortDir)})`,
  id: sortOptionId(col.key, sortDir),
  columnKey: col.key,
  direction: sortDir,
});

export const buildTableViewModel = (
  config: TableMetadata,
  rows: Row[],
  viewPath?: (row: RowViewModel) => string,
): TableViewModel => ({
  config,
  rows: rows.map((row) => ({
    row,
    viewPath,
  })),
  viewPath,
});

export const EMPTY_FILTER_VALUE = '@empty@';

const filterMatches = (filterTerm: string, valueToFilter?: string) =>
  filterTerm === valueToFilter ||
  (filterTerm === EMPTY_FILTER_VALUE &&
    (valueToFilter === '' || valueToFilter === undefined));

export const filterRow = (
  column: Column,
  data: ColumnData[],
  filterTerm: string | string[],
) => {
  const valueToFilter = columnValue(data, column.key);
  if (
    valueToFilter &&
    (column.dataType === ColumnDataType.Date ||
      column.dataType === ColumnDataType.DateTime)
  ) {
    if (filterTerm[0] && filterTerm[1]) {
      return isBetween(valueToFilter, filterTerm[0], filterTerm[1]);
    }
    if (filterTerm[0]) {
      return isSameOrAfter(valueToFilter, filterTerm[0]);
    }
    if (filterTerm[1]) {
      return isSameOrBefore(valueToFilter, filterTerm[1]);
    }
    return true;
  }
  if (Array.isArray(filterTerm)) {
    return filterTerm.some((ft) => filterMatches(ft, valueToFilter));
  }
  return filterMatches(filterTerm, valueToFilter);
};

export const searchRow = (
  column: Column,
  data: ColumnData[],
  filterTerm: string,
) => {
  let valueToFilter = columnValue(data, column.key);
  if (valueToFilter && column.dataType === ColumnDataType.Date) {
    valueToFilter = formatDate(valueToFilter.toString());
  } else if (valueToFilter && column.dataType === ColumnDataType.DateTime) {
    valueToFilter = formatDateOrDateTime(valueToFilter.toString());
  }
  // get the value from the column filter field
  const filterValue = new RegExp(filterTerm.trim(), 'i');
  return filterValue.test(valueToFilter || '');
};

export const sanitiseKey = (key: string) => key.replaceAll('.', '_');

export const sanitiseFilterModel = (obj: FilterModel) => {
  const sanitisedObj: FilterModel = {};
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    sanitisedObj[sanitiseKey(key)] = value;
  });
  return sanitisedObj;
};
