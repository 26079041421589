import { render, staticRenderFns } from "./DeleteGroupAction.vue?vue&type=template&id=12ce717b&scoped=true&"
import script from "./DeleteGroupAction.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DeleteGroupAction.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DeleteGroupAction.vue?vue&type=style&index=0&id=12ce717b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ce717b",
  null
  
)

export default component.exports