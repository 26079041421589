import axios, { AxiosResponse } from 'axios';
import logger from '@/logger';
import { Configuration } from './types';

// This is a constant outside the exported getter function,
// so that it is only loaded once and can then be reused every
// time we need it
let configPromise: Promise<Configuration>;

const getConfigPromise = () =>
  axios
    .get<Configuration>(`${process.env.BASE_URL}config.json`)
    .then((response: AxiosResponse) => {
      const config = response.data;
      logger.debug('Retrieved config:', config);
      return typeof config === 'string' ? JSON.parse(config) : config;
    })
    .catch((err) => {
      logger.error('Failed to load config.json. Error:', err);
      throw err;
    });

export default (): Promise<Configuration> => {
  if (!configPromise) {
    configPromise = getConfigPromise();
  }
  return configPromise;
};
