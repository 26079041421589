import Vue from 'vue';
import App from '@/App.vue';
import RplGlobal from '@dpc-sdp/ripple-global';
import router from '@/router';
import store from '@/store';
import VueGtag from 'vue-gtag';
import config from '@/config';

Vue.config.productionTip = false;
Vue.use(RplGlobal, { viclogo: false });

config().then((c) =>
  Vue.use(
    VueGtag,
    {
      config: { id: 'GTM-K3NDNWK2' },
      disableScriptLoad: !c.featureFlags.collectAnalytics,
    },
    router,
  ),
);

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount('#app');
