
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import { columnValue } from '@/helpers/table';
import TextIconLink from '@/components/ripple/TextIconLink.vue';
import IconButton from '@/components/ripple/form/IconButton.vue';

type Data = { downloading: boolean };
type Computed = unknown;

interface Methods {
  onClick: (event: Event) => void;
}

type Props = { rowViewModel: RowViewModel; column: Column; label?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DownloadAction',
  components: { TextIconLink, IconButton },
  data() {
    return { downloading: false };
  },
  props: { rowViewModel: Object, column: Object, label: String },
  methods: {
    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      this.downloading = true;
      try {
        // {ppars}/v1/reports/a/b
        const url = columnValue(this.rowViewModel.row.columns, this.column.key);
        const fileDownload = await this.$store.dispatch('secureDownload', url);
        const blob = new Blob([fileDownload.buffer]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileDownload.fileName
          ? fileDownload.fileName
          : this.column.key;
        link.click();
      } finally {
        this.downloading = false;
      }
    },
  },
});
