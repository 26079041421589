import { computed, Ref, ComputedRef, unref } from 'vue';
import templateString, {
  type TemplateString,
} from '@/templating/templateString';

function useTypedResultTemplateString(
  value: string | Ref<string>,
  values: Record<string, unknown | Ref<unknown>>,
): ComputedRef<TemplateString> {
  return computed(() => {
    const compValues = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [k, unref(v)]),
    );

    const v = unref(value);
    return templateString(v, compValues);
  });
}

export default function useTemplateString(
  url: string | Ref<string>,
  values: Record<string, unknown | Ref<unknown>>,
): ComputedRef<string> {
  const result = useTypedResultTemplateString(url, values);
  return computed(() => {
    if (!result.value.success) {
      return '';
    }
    return result.value.value;
  });
}
