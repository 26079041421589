import { ActionContext, Commit, Module } from 'vuex';

import { ApiCallName, DataHubClient } from '@/api/types';
import {
  ApiLoading,
  ApiNeverLoaded,
  ApiResponse,
  DataFromApi,
  isApiError,
} from '@/api/data';
import { API_CALL_NAMES, dataHubClient } from '@/api';
import { ApiCallResponses, ApiCallState } from './types';
import { RootState } from '../types';

type VuexDeps = {
  commit: Commit;
  rootState: RootState;
  rootGetters: ActionContext<RootState, unknown>['rootGetters'];
};

type SetApiDataPayload<T> = {
  apiCall: ApiCallName;
  data: ApiResponse<T> | ApiLoading<T>;
};

const makePayload = <T>(
  apiCall: ApiCallName,
  data: ApiResponse<T> | ApiLoading<T>,
): SetApiDataPayload<T> => ({
  apiCall,
  data,
});

export const recordApiCall = <
  C extends ApiCallName,
  R extends ReturnType<DataHubClient[C]>,
>(
  { commit, rootState }: VuexDeps,
  callName: C,
  callFn: (client: DataHubClient) => R,
): R => {
  commit(
    'setApiData',
    makePayload(callName, ApiLoading(rootState.apiCalls.responses[callName])),
    { root: true },
  );
  return dataHubClient()
    .then(callFn)
    .then((response) => {
      commit('setApiData', makePayload(callName, response), { root: true });
      return response;
    }) as R;
};

const apiCallsModule: Module<ApiCallState, RootState> = {
  state: () => ({
    responses: API_CALL_NAMES.reduce(
      (acc, curr) => ({ ...acc, [curr]: ApiNeverLoaded }),
      {} as ApiCallResponses,
    ),
  }),
  mutations: {
    setApiData<T>(state: ApiCallState, payload: SetApiDataPayload<T>) {
      (state.responses[payload.apiCall] as DataFromApi<T>) = payload.data;
    },
  },
  getters: {
    apiErrors(state: ApiCallState) {
      return API_CALL_NAMES.map(
        (name: keyof ApiCallResponses) => state.responses[name],
      ).filter((resp) => isApiError(resp));
    },
  },
};

export default apiCallsModule;
