import { ActionContext, Module } from 'vuex';
import { recordApiCall } from '@/store/modules/apiCalls';
import { getData, getErrorMessage, isApiError } from '@/api/data';
import { RootState } from '../types';

const batchmodule: Module<{}, RootState> = {
  actions: {
    getDownloadUrl(
      { commit, rootState, rootGetters }: ActionContext<{}, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getDownloadUrl',
        (client) => client.getDownloadUrl(url),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },

    downloadBatch(
      { commit, rootState, rootGetters }: ActionContext<{}, RootState>,
      args: { batchCode: string; endpoint: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'downloadBatch',
        (client) => client.downloadBatch(args.batchCode, args.endpoint),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
    downloadBatchByType(
      { commit, rootState, rootGetters }: ActionContext<{}, RootState>,
      args: { batchCode: string; dataType: string; endpoint: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'downloadBatchByType',
        (client) =>
          client.downloadBatchByType(
            args.batchCode,
            args.dataType,
            args.endpoint,
          ),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
  },
};

export default batchmodule;
