
import Vue from 'vue';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import GearIcon from '@/assets/cog-regular.svg?component';
import IconButton from '@/components/ripple/form/IconButton.vue';
import Popup from '@/components/shared/Popup.vue';

type Data = {};

type Computed = {};

type Methods = {
  isSelected: (id: string) => boolean;
  onChange: (id: string, selected: boolean) => void;
};

type Props = {
  columnOptions: { name: string; id: string }[];
  selectedColumns: string[];
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ColumnConfig',
  components: { Popup, IconButton, GearIcon, RplCheckbox },
  props: {
    columnOptions: Array,
    selectedColumns: Array,
  },
  methods: {
    isSelected(id) {
      return this.selectedColumns.includes(id);
    },
    onChange(id, selected) {
      this.$emit('configure-column', { id, selected });
    },
  },
});
