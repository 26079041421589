
import Vue from 'vue';
import FormButton from '@/components/ripple/form/FormButton.vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import { Group } from '@/models/group.model';
import {
  DataFromApi,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
} from '@/api/data';
import { UserProfile } from '@/models/user.model';

Vue.component('field-done-button', FormButton);

type Data = {
  model: { email: string; firstName?: string; lastName?: string };
  working: boolean;
  response: { response?: { message: string; status: string } };
  captureUserDetails: boolean;
};
type Methods = {
  onContinue: () => void;
  showFormMessage: (message: string, status: string) => void;
};
type Computed = {
  group: Group;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  formData: object;
};
type Props = { groupId: string; code: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AddGroupMember',
  props: { groupId: String, code: String },
  components: { RplIcon, RplForm },
  data() {
    return {
      model: { email: '' },
      working: false,
      response: {},
      captureUserDetails: false,
    };
  },
  computed: {
    isLoading() {
      return isLoading(this.$store.state.group.groupFromApi);
    },
    isError() {
      return isApiError(this.$store.state.group.groupFromApi);
    },
    group() {
      return getData(this.$store.state.group.groupFromApi);
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.group.groupFromApi) ||
        'Error loading group'
      );
    },
    formData() {
      return {
        model: this.model,
        tag: 'rpl-fieldset',
        schema: {
          fields: [
            {
              type: 'input',
              inputType: 'email',
              label: 'Email address of new member',
              model: 'email',
              required: true,
              disabled: this.captureUserDetails,
              max: 2048,
              validator: VueFormGenerator.validators.email,
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'First name',
              model: 'firstName',
              required: true,
              visible: this.captureUserDetails,
              max: 2048,
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'Last name',
              model: 'lastName',
              required: true,
              visible: this.captureUserDetails,
              max: 2048,
            },
          ],
          groups: [
            {
              inline: true,
              fields: [
                {
                  type: 'done-button',
                  buttonText: 'Back',
                  clickHandler: () =>
                    this.$router.push({
                      name: 'group',
                      params: { id: this.groupId },
                    }),
                  styleClasses: 'form-group--inline',
                },
                {
                  type: 'rplsubmitloader',
                  buttonText: 'Add',
                  loading: this.working,
                  autoUpdate: true,
                  styleClasses: 'form-group--inline',
                },
              ],
            },
          ],
        },
        formState: this.response,
        formOptions: {
          validateAfterChanged: false,
        },
      };
    },
  },
  methods: {
    onContinue() {
      if (
        this.group.contacts.some(
          (contact) =>
            contact.email &&
            contact.email.toLowerCase() === this.model.email.toLowerCase(),
        )
      ) {
        this.showFormMessage(
          `User ${this.model.email} is already a member of ${this.group.name}.`,
          'error',
        );
        return;
      }

      this.working = true;
      if (this.captureUserDetails) {
        this.$store
          .dispatch('addNewUserToGroup', {
            user: this.model,
            groupId: this.group.id,
            dashboardCode: this.code,
          })
          .then((addUserResponse: DataFromApi<Group>) => {
            if (!isApiError(addUserResponse)) {
              this.showFormMessage(
                `${this.model.email} added to group, add another or click Back to return to list`,
                'success',
              );
              this.model = { email: '' };
            } else {
              this.showFormMessage(getErrorMessage(addUserResponse), 'error');
            }
            this.captureUserDetails = false;
            this.working = false;
          });
      } else {
        this.showFormMessage('Searching for user', 'info');
        this.$store
          .dispatch('findUser', {
            email: this.model.email,
            dashboardCode: this.code,
          })
          .then((response: DataFromApi<UserProfile>) => {
            if (!isApiError(response)) {
              if (hasData(response) && getData(response).firstName) {
                this.showFormMessage('User found, adding to group', 'info');
                this.$store
                  .dispatch('addExistingUserToGroup', {
                    dashboardCode: this.code,
                    user: { ...getData(response), email: this.model.email },
                    groupId: this.group.id,
                  })
                  .then((addUserResponse: DataFromApi<Group>) => {
                    if (!isApiError(addUserResponse)) {
                      this.showFormMessage(
                        `${this.model.email} added to group, add another or click Back to return to list`,
                        'success',
                      );
                      this.model = { email: '' };
                    } else {
                      this.showFormMessage(
                        getErrorMessage(addUserResponse),
                        'error',
                      );
                    }
                    this.working = false;
                  });
              } else {
                this.showFormMessage(
                  'User not found, we need a few more details',
                  'info',
                );
                this.captureUserDetails = true;
                this.working = false;
              }
            } else {
              this.showFormMessage(getErrorMessage(response), 'error');
              this.working = false;
            }
          });
      }
    },
    showFormMessage(message, status) {
      this.response = { response: { message, status } };
    },
  },
  mounted() {
    if (
      !hasData(
        this.$store.state.group.groupFromApi || this.group.id !== this.groupId,
      )
    ) {
      this.$store.dispatch('getGroup', {
        dashboardCode: this.code,
        groupId: this.groupId,
      });
    }
    this.$store.commit('setHeaderText', 'Add group member');
    this.$store.commit('setSubText', '');
  },
});
