import { render, staticRenderFns } from "./DownloadTemplateResourceAction.vue?vue&type=template&id=133a8ea3&"
import script from "./DownloadTemplateResourceAction.vue?vue&type=script&lang=ts&"
export * from "./DownloadTemplateResourceAction.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports