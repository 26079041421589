import { ActionContext, Module } from 'vuex';
import { cloneDeep } from 'lodash';

import { EmailTransfer, EmailData } from '@/components/widgets/comms/types';
import { ReplyEmailState } from './types';
import { RootState } from '../types';

const email: Module<ReplyEmailState, RootState> = {
  state: () => ({
    actionedEmailData: null,
    type: null,
  }),

  getters: {
    actionEmailData(state): EmailData | null {
      return state.actionedEmailData;
    },
    actionEmailType(state): string | null {
      return state.type;
    },
  },
  mutations: {
    handleEmail(state: ReplyEmailState, emailData: EmailTransfer): void {
      const copy = cloneDeep(emailData.data);

      if (emailData.type === 'reply') {
        copy.attachments = [];
      }

      copy.updatedAt = emailData.updatedAt;
      state.type = emailData.type;
      state.actionedEmailData = copy;
    },
    clearData(state: ReplyEmailState): void {
      state.actionedEmailData = null;
      state.type = null;
    },
  },

  actions: {
    clearEmailData({
      commit,
    }: ActionContext<ReplyEmailState, RootState>): void {
      commit('clearData');
    },
    addEmailData(
      { commit }: ActionContext<ReplyEmailState, RootState>,
      payload: EmailTransfer,
    ): void {
      commit('handleEmail', payload);
    },
  },
};

export default email;
