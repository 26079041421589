
import Vue, { inject } from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { RowViewModel, Column } from '@/models/table.model';
import { FileDownload } from '@/api/data';
import { ProvidedProperty } from '@/models/dashboard.model';
import { findPropertyValue, PROVIDED_PROPS_KEY } from '@/helpers/property';
import logger from '@/logger';

type Data = {
  downloading: boolean;
  isDownloadBatchData: boolean;
  providedProperties: ProvidedProperty[];
};
type Computed = unknown;

interface Methods {
  onClick: (event: Event) => void;
}

type Props = { rowViewModel: RowViewModel; column: Column };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'BatchDownloadAction',
  components: { RplIcon },
  data() {
    return {
      downloading: false,
      isDownloadBatchData: true,
      providedProperties: [],
    };
  },
  props: { rowViewModel: Object, column: Object },
  methods: {
    async onClick(event: Event) {
      const endpoint = findPropertyValue(
        'BATCHES_ENDPOINT',
        this.providedProperties,
      );
      if (endpoint) {
        event.stopPropagation();
        event.preventDefault();
        this.downloading = true;
        let fileDownload: FileDownload;
        if (this.column.key === 'actions') {
          fileDownload = await this.$store.dispatch('downloadBatch', {
            batchCode: this.rowViewModel.row.id,
            endpoint,
          });
        } else {
          fileDownload = await this.$store.dispatch('downloadBatchByType', {
            batchCode: this.rowViewModel.row.id,
            dataType: this.column.key,
          });
        }
        const blob = new Blob([fileDownload.buffer], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download =
          fileDownload.fileName || `Batch_${this.rowViewModel.row.id}.csv`;
        link.click();
        this.downloading = false;
      } else {
        logger.error('BATCHES_ENDPOINT property not found');
      }
    },
  },
  mounted() {
    this.isDownloadBatchData = this.column.key === 'actions';
  },
  created() {
    this.providedProperties = inject(PROVIDED_PROPS_KEY) || [];
  },
});
