import { ActionContext, Module } from 'vuex';
import { Group, GroupDetails, GroupMember } from '@/models/group.model';
import { recordApiCall } from '@/store/modules/apiCalls';
import {
  ApiErrorType,
  ApiLoading,
  ApiNeverLoaded,
  DataFromApi,
  getErrorMessage,
  isApiError,
} from '@/api/data';
import { User } from '@/models/user.model';
import { RootState, GroupState } from '../types';

const groupmodule: Module<GroupState, RootState> = {
  state: () => ({
    groupsFromApi: ApiNeverLoaded,
    groupFromApi: ApiNeverLoaded,
    message: undefined,
    error: undefined,
  }),
  mutations: {
    setGroups(state, groups: DataFromApi<Group[]>) {
      state.groupsFromApi = groups;
    },
    setGroup(state, group: DataFromApi<Group>) {
      state.groupFromApi = group;
      state.message = undefined;
      state.error = undefined;
    },
    setMessage(state, message: string) {
      state.message = message;
    },
    setError(state, error: string) {
      state.error = error;
    },
  },
  actions: {
    loadGroups(
      { commit, rootGetters, rootState }: ActionContext<GroupState, RootState>,
      dashboardCode: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getGroups',
        (client) => client.getGroups(dashboardCode),
      ).then((response) => {
        commit('setGroups', response);
      });
    },
    reloadGroups({ dispatch, commit }: ActionContext<GroupState, RootState>) {
      commit('setGroups', ApiNeverLoaded);
      commit('setGroup', ApiNeverLoaded);
      commit('setMessage', undefined);
      commit('setError', undefined);
      return dispatch('loadGroups');
    },
    getGroup(
      {
        state,
        commit,
        rootGetters,
        rootState,
      }: ActionContext<GroupState, RootState>,
      args: { dashboardCode: string; groupId: string },
    ) {
      commit('setGroup', ApiLoading(state.groupFromApi));
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getGroup',
        (client) => client.getGroup(args.dashboardCode, args.groupId),
      ).then((response) => {
        commit('setGroup', response);
      });
    },
    addExistingUserToGroup(
      { commit, rootGetters, rootState }: ActionContext<GroupState, RootState>,
      args: {
        dashboardCode: string;
        user: User;
        groupId: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addExistingUserToGroup',
        (client) =>
          client.addExistingUserToGroup(
            args.dashboardCode,
            args.user,
            args.groupId,
          ),
      ).then((response: DataFromApi<Group>) => {
        if (!isApiError(response)) {
          commit('setGroup', response);
          commit('setMessage', `${args.user.email} added to group`);
        }
      });
    },
    addMemberToGroup(
      { commit, rootState, rootGetters }: ActionContext<GroupState, RootState>,
      args: {
        url: string;
        body: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addMemberToGroup',
        (client) => client.addMemberToGroup(args.url, args.body),
      ).then((response) => {
        if (!isApiError(response)) {
          return response;
        }
        throw new Error(getErrorMessage(response));
      });
    },
    removeUserFromGroup(
      { commit, rootState, rootGetters }: ActionContext<GroupState, RootState>,
      args: {
        url: string;
        body: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeUserFromGroup',
        (client) => client.removeUserFromGroup(args.url, args.body),
      ).then((response) => {
        if (!isApiError(response)) {
          return response;
        }
        throw new Error(getErrorMessage(response));
      });
    },
    addNewUserToGroup(
      { commit, rootGetters, rootState }: ActionContext<GroupState, RootState>,
      args: {
        dashboardCode: string;
        user: User;
        groupId: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addNewUserToGroup',
        (client) =>
          client.addNewUserToGroup(args.dashboardCode, args.user, args.groupId),
      ).then((response: DataFromApi<Group>) => {
        if (!isApiError(response)) {
          commit('setGroup', response);
          commit('setMessage', `${args.user.email} added to group`);
        }
      });
    },
    removeContactFromGroup(
      {
        commit,
        dispatch,
        rootGetters,
        rootState,
      }: ActionContext<GroupState, RootState>,
      args: {
        dashboardCode: string;
        contact: GroupMember;
        groupId: string;
        self: boolean;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeContactFromGroup',
        (client) =>
          client.removeContactFromGroup(
            args.dashboardCode,
            args.contact.id,
            args.groupId,
          ),
      ).then((response: DataFromApi<Group>) => {
        // api will return 404 if self removed from group
        if (
          (args.self &&
            isApiError(response) &&
            response.type === ApiErrorType.NotFound) ||
          !isApiError(response)
        ) {
          if (args.self) {
            dispatch('reloadGroups');
          }
          commit('setGroup', response);
          commit(
            'setMessage',
            `${args.contact.firstName || ''} ${
              args.contact.lastName || ''
            } removed from group`,
          );
        } else {
          commit('setError', getErrorMessage(response));
        }
      });
    },
    updateGroupDetails(
      {
        commit,
        rootGetters,
        rootState,
        dispatch,
      }: ActionContext<GroupState, RootState>,
      args: {
        dashboardCode: string;
        groupDetails: GroupDetails;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateGroupDetails',
        (client) =>
          client.updateGroupDetails(args.groupDetails, args.dashboardCode),
      ).then((response: DataFromApi<Group>) => {
        if (!isApiError(response)) {
          dispatch('reloadGroups');
          commit('setMessage', `Group updated`);
        }
        return response;
      });
    },
  },
};

export default groupmodule;
