
import { defineComponent } from 'vue';
import Button from '@/components/ripple/Button.vue';
import RplAlert from '@dpc-sdp/ripple-alert';

export default defineComponent({
  name: 'Alert',
  components: { RplAlert, Button },
  props: {
    // for valid types see https://github.com/dpc-sdp/ripple/blob/master/packages/components/Molecules/Alert/Alert.vue
    type: { type: String, required: true },
    title: { type: String, required: true },
    message: { type: String, required: true },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
});
