
import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplSelect } from '@dpc-sdp/ripple-form';
import { GroupDetails } from '@/models/group.model';
import { getData, getErrorMessage, isApiError, isLoading } from '@/api/data';
import logger from '@/logger';
import Button from '@/components/ripple/Button.vue';

type Data = { selectedGroupId?: string };
type Methods = {
  onSelect: (id: string) => void;
  onManageGroupDetails: () => void;
};
type Computed = {
  groups: GroupDetails[];
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
};
type Props = { code: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Groups',
  components: { Button, RplIcon, RplSelect },
  data() {
    return { selectedGroupId: undefined };
  },
  props: { code: String },
  computed: {
    isLoading() {
      return isLoading(this.$store.state.group.groupsFromApi);
    },
    isError() {
      return isApiError(this.$store.state.group.groupsFromApi);
    },
    groups() {
      const groups: GroupDetails[] = getData(
        this.$store.state.group.groupsFromApi,
      );
      return groups?.sort((g1, g2) => g1.name.localeCompare(g2.name)) || [];
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.group.groupsFromApi) ||
        'Error loading groups'
      );
    },
  },
  methods: {
    onSelect(id: string) {
      this.selectedGroupId = id;
    },
    onManageGroupDetails() {
      this.$router.push({
        name: 'editGroupDetails',
        params: { groupId: this.selectedGroupId || '', code: this.code },
      });
    },
  },
  mounted() {
    this.$store.dispatch('loadGroups', this.code);
    this.$store.commit('setHeaderText', 'Manage groups');
    this.$store.commit('setSubText', '');
    this.selectedGroupId = this.$route.params.id;
    this.$store.commit('setBreadcrumbs', [{ text: 'Manage groups' }]);
  },
  watch: {
    groups() {
      if (this.groups.length > 0) {
        if (!this.selectedGroupId) {
          this.selectedGroupId = this.groups[0].id;
        }
      } else {
        this.selectedGroupId = undefined;
      }
    },
    selectedGroupId(newId, oldId) {
      if (newId) {
        const route = {
          name: 'group',
          params: { id: newId },
        };
        if (oldId) {
          this.$router.push(route);
        } else {
          this.$router
            .replace(route)
            .catch(() => logger.debug('ignore duplicate navigation'));
        }
      } else {
        this.$router.push({ name: 'groups' });
      }
    },
  },
});
