import appConfig from '@/config';
import axios, { AxiosResponse } from 'axios';

import restClient from './restClient';
import {
  CreateResourceRequest,
  IdResponse,
  UpdateResourceRequest,
  GetResourceResponse,
  GetAttachmentResponse,
  GetAttachmentDownloadUrlResponse,
  ListAttachmentResponse,
  ListResourceResponse,
  CreateAttachmentRequest,
  CreateAttachmentResponse,
  LookupsResponse,
  LookupResponse,
} from './appEngineApi.types';

class AppEngineApi {
  async createResource(request: CreateResourceRequest): Promise<IdResponse> {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources`;

    return restClient
      .post<IdResponse, CreateResourceRequest>(url, request)
      .then(this.extractData);
  }

  async updateResource(
    resourceId: string,
    request: UpdateResourceRequest,
  ): Promise<IdResponse> {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}`;

    return restClient
      .patch<IdResponse, UpdateResourceRequest>(url, request)
      .then(this.extractData);
  }

  async getResource<T>(resourceId: string): Promise<GetResourceResponse<T>> {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}`;

    return restClient.get<GetResourceResponse<T>>(url).then(this.extractData);
  }

  async getResources<T>(params = undefined) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources`;

    return restClient
      .get<ListResourceResponse<T>>(url, params)
      .then(this.extractData)
      .then((d) => d.results);
  }

  async getAttachment(resourceId: string, attachmentId: string) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}/attachments/${attachmentId}`;

    return restClient.get<GetAttachmentResponse>(url).then(this.extractData);
  }

  async getAttachmentDownloadUrl(resourceId: string, attachmentId: string) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}/attachments/${attachmentId}/download-url`;

    return restClient
      .get<GetAttachmentDownloadUrlResponse>(url)
      .then(this.extractData);
  }

  async downloadAttachment(signedUrl: string): Promise<Blob> {
    const data = await axios.request({
      method: 'get',
      responseType: 'arraybuffer',
      url: signedUrl,
    });
    return new Blob([data.data]);
  }

  async getAttachments(
    resourceId: string,
    params: Record<string, unknown> = {},
  ) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}/attachments`;

    return restClient
      .get<ListAttachmentResponse>(url, params)
      .then(this.extractData)
      .then((r) => r.results);
  }

  async createAttachment(
    resourceId: string,
    attachment: CreateAttachmentRequest,
  ) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}/attachments`;

    return restClient
      .post<CreateAttachmentResponse, CreateAttachmentRequest>(url, attachment)
      .then(this.extractData);
  }

  async deleteAttachment(resourceId: string, attachmentId: string) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${resourceId}/attachments/${attachmentId}`;

    return restClient.delete<unknown>(url).then(this.extractData);
  }

  async copyAttachments<T>(
    originResourceId: string,
    targetResourceId: string,
    attachmentList: T[],
  ) {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/resources/${originResourceId}/attachments/copy`;

    return restClient
      .post<unknown, { resourceId: string; attachments: T[] }>(url, {
        resourceId: targetResourceId,
        attachments: attachmentList,
      })
      .then(this.extractData);
  }

  async getLookups(
    category: string,
    subCategory?: string,
  ): Promise<LookupResponse[]> {
    const config = await appConfig();
    const url = `${config.appEngineApiUrl}/lookup/${category}`;

    return (
      await restClient
        .get<LookupsResponse>(url, { subCategory })
        .then(this.extractData)
    ).results;
  }

  private extractData<T>(response: AxiosResponse<T>): T {
    return response.data;
  }
}

const api = new AppEngineApi();

export default api;
