
import Vue from 'vue';
import Graphic from '@/assets/triangles-top.svg?component';
import { isLoading } from '@/api/data';

type Data = {};
type Computed = {
  showGraphic: boolean;
  colour?: string;
};
type Methods = {};
type Props = {};
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HeaderGraphic',
  components: { Graphic },
  computed: {
    showGraphic() {
      return !isLoading(this.$store.state.dashboard.dashboardFromApi);
    },
    colour() {
      return this.$store.getters.headerImgColour;
    },
  },
});
