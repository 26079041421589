import { Module } from 'vuex';
import { Breadcrumb } from '@/models/site.model';
import { RootState } from '../types';
import { BreadcrumbState } from './types';

const breadcrumb: Module<BreadcrumbState, RootState> = {
  state: () => ({
    root: undefined,
    breadcrumbs: [],
  }),
  getters: {
    crumbs: (state) => (state.root ? [state.root, ...state.breadcrumbs] : []),
  },
  mutations: {
    setRootBreadCrumb(state: BreadcrumbState, crumb: Breadcrumb) {
      state.root = crumb;
    },
    setBreadcrumbs(state: BreadcrumbState, crumbs: Breadcrumb[]) {
      state.breadcrumbs = crumbs;
    },
  },
};

export default breadcrumb;
