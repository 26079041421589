import { ActionContext, Module } from 'vuex';
import { ApiNeverLoaded, ApiResponse, getData, hasData } from '@/api/data';
import { Dashboard } from '@/models/dashboard.model';
import { recordApiCall } from '@/store/modules/apiCalls';
import Vue from 'vue';
import { DashboardState } from './types';
import { RootState } from '../types';

const dashboardmodule: Module<DashboardState, RootState> = {
  state: () => ({
    dashboardFromApi: ApiNeverLoaded,
    eventBus: new Vue(),
  }),
  mutations: {
    setDashboard(state, dashboard: ApiResponse<Dashboard>) {
      state.dashboardFromApi = dashboard;
    },
  },
  getters: {
    headerBgColour(state) {
      return hasData(state.dashboardFromApi)
        ? getData(state.dashboardFromApi).headerBgColour
        : undefined;
    },
    headerImgColour(state) {
      return hasData(state.dashboardFromApi)
        ? getData(state.dashboardFromApi).headerImgColour
        : undefined;
    },
  },
  actions: {
    loadDashboard(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<DashboardState, RootState>,
      dashboardCode: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadDashboard',
        (client) => client.loadDashboard(dashboardCode),
      ).then((response) => {
        commit('setDashboard', response);
      });
    },
    loadPublicDashboard(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<DashboardState, RootState>,
      dashboardCode: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadPublicDashboard',
        (client) => client.loadPublicDashboard(dashboardCode),
      ).then((response) => {
        commit('setDashboard', response);
      });
    },
  },
};

export default dashboardmodule;
