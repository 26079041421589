
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import TextIconLink from '@/components/ripple/TextIconLink.vue';
import IconButton from '@/components/ripple/form/IconButton.vue';
import {
  ListResourceAttachmentResponse,
  ResourceDto,
} from '@/models/resource.model';

type Data = { downloading: boolean };
type Computed = unknown;

interface Methods {
  onClick: (event: Event) => void;
}

type Props = { rowViewModel: RowViewModel; column: Column; label?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DownloadTemplateResourceAction',
  components: { TextIconLink, IconButton },
  data() {
    return { downloading: false };
  },
  props: { rowViewModel: Object, column: Object, label: String },
  methods: {
    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      this.downloading = true;
      try {
        const resourceId = this.rowViewModel.row.id;
        const resource: ResourceDto<{ [key: string]: unknown | undefined }> =
          await this.$store.dispatch('getResource', resourceId);
        const resourceAttachmentResponse = (await this.$store.dispatch(
          'getResourceAttachments',
          resourceId,
        )) as ListResourceAttachmentResponse;
        const resourceAttachments =
          resourceAttachmentResponse?.results?.map((resourceAttachment) => ({
            // remove unwanted fields
            ...resourceAttachment,
            createdAt: undefined,
            uploadedBy: undefined,
            virusScanStatus: undefined,
            status: undefined,
          })) ?? [];

        const blob = new Blob(
          [
            JSON.stringify(
              {
                template: {
                  // remove unwanted fields
                  ...resource,
                  humanReadableId: { prefix: resource.type },
                  allowableActions: undefined,
                  permissions: undefined,
                  createdAt: undefined,
                  updatedAt: undefined,
                  userUpdatedAt: undefined,
                  data: {
                    ...(resource.data ?? {}),
                    camundaInstanceId: undefined,
                    statusTransitions: undefined,
                    allowableActions: undefined,
                    clocks: undefined,
                  },
                },
                ...(resourceAttachments.length > 0
                  ? { resourceAttachments }
                  : {}),
              },
              null,
              2,
            ),
          ],
          {
            type: 'application/json',
          },
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        const fileName = `${resource.data?.name}`
          ?.toLowerCase()
          .replaceAll(/[\W_ ]+/g, '_');

        link.download =
          fileName.trim().length > 0
            ? `${fileName}.json`
            : `${resource.id}.json`;
        link.click();
        link.remove();
      } finally {
        this.downloading = false;
      }
    },
  },
});
