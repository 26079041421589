/**
 * If you need paths outside the router, don't use this one - please use `router/paths.ts`
 * This is the definition for the path fragments inside the router.
 */
export const pathFragments = {
  // login
  loggedOut: () => `loggedOut`,
  login: () => `login`,
  logout: () => `logout`,
  groups: () => `groups`,
  group: (groupId: string) => `group/${groupId}`,
  addGroupMember: (groupId: string) => `addGroupMember/${groupId}`,
  editGroupDetails: (groupId: string) => `editGroupDetails/${groupId}`,
  dashboard: (code: string) => code,
  page: (pageCode: string) => `page/${pageCode}`,
  changePassword: () => 'changePassword',
  mfa: () => 'mfa',
  public: (code: string) => `public/${code}`,
  profile: () => `profile`,
};
