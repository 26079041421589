import { ProvidedProperty, WidgetProperty } from '@/models/dashboard.model';

export const PROVIDED_PROPS_KEY = 'PROVIDED_PROPERTIES';

export const findPropertyValue = (
  code: string,
  properties: ProvidedProperty[],
): string | undefined => properties.find((p) => p.code === code)?.value;

export const buildProvidedProperties = (
  widgetProperties: WidgetProperty[],
) => ({
  [PROVIDED_PROPS_KEY]: widgetProperties.map((wp) => ({
    code: wp.definitionCode,
    value: wp.value,
  })) as ProvidedProperty[],
});
