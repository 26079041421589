import { ActionContext, Module } from 'vuex';
import { recordApiCall } from '@/store/modules/apiCalls';
import { getData, getErrorMessage, isApiError } from '@/api/data';
import { RootState } from '../types';

const download: Module<{}, RootState> = {
  actions: {
    secureDownload(
      { commit, rootState, rootGetters }: ActionContext<{}, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'secureDownload',
        (client) => client.secureDownload(url),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
  },
};

export default download;
