
import Vue from 'vue';
import { DataFromApi, getData, hasData } from '@/api/data';
import { Dashboard, Page } from '@/models/dashboard.model';
import PageComponent from '@/components/pages/Page.vue';

type Data = {};
type Computed = {
  dashboard?: Dashboard;
  page: Page;
  fullScreen: boolean;
};
type Methods = {
  setFullScreen: () => void;
};
type Props = { pageCode: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'PageLayoutController',
  components: { PageComponent },
  props: { pageCode: String },
  computed: {
    dashboard() {
      const dataFromApi: DataFromApi<Dashboard> =
        this.$store.state.dashboard.dashboardFromApi;
      return hasData(dataFromApi) ? getData(dataFromApi) : undefined;
    },
    page() {
      const pg = this.dashboard?.pages?.find(
        (page) => page.code === this.pageCode,
      );
      if (!pg) {
        throw new Error('Page not found');
      }
      return pg;
    },
    fullScreen() {
      return !!this.page.fullScreen;
    },
  },
  methods: {
    setFullScreen() {
      // Manipulate the ripple layout through direct dom manipulation, RplBaseLayout doesn't allow for configuration of screen width through props
      const layoutEl = this.$el.closest('.rpl-site-constrain--on-all');
      if (this.fullScreen) {
        layoutEl?.classList.add('full-screen');
      } else {
        layoutEl?.classList.remove('full-screen');
      }
    },
  },
  mounted() {
    this.setFullScreen();
  },
  watch: {
    fullScreen() {
      this.setFullScreen();
    },
  },
  destroyed() {
    document
      .querySelector('.rpl-site-constrain--on-all.full-screen')
      ?.classList.remove('full-screen');
  },
});
