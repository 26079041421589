
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};

type Computed = {
  summable: boolean;
  total: string;
  currency: boolean;
};

type Methods = {};

type Props = { column: Column; rows: RowViewModel[] };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TotalCell',
  props: { column: Object, rows: Array },
  computed: {
    summable() {
      return !!this.column.summable;
    },
    total() {
      if (this.summable) {
        const total: number = this.rows.reduce((sum, viewModel) => {
          const value = columnValue(viewModel.row.columns, this.column.key);
          if (value) {
            const numericalValue = Number.parseFloat(
              value.replaceAll(/[^0-9.eE]/g, ''),
            );
            if (!Number.isNaN(numericalValue)) {
              return sum + numericalValue;
            }
          }
          return sum;
        }, 0.0);
        return this.currency
          ? `$${total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : total.toLocaleString();
      }
      return '';
    },
    currency() {
      return this.rows.some((viewModel) => {
        const value = columnValue(viewModel.row.columns, this.column.key);
        return !!(value && value.toString().startsWith('$'));
      });
    },
  },
});
