<template>
  <vue-markdown class="markdown" :source="source" />
</template>

<script>
import VueMarkdown from 'vue-markdown-render';

export default {
  name: 'Markdown',
  props: ['source'],
  components: {
    VueMarkdown,
  },
};
</script>
<style lang="scss">
.markdown {
  p,
  li {
    white-space: pre-line;
  }
}
</style>
