
import Vue from 'vue';
import RplLink from '@dpc-sdp/ripple-link';
import { Column, RowViewModel } from '@/models/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};

type Computed = { path?: string; text: string };

type Methods = { onClick: (event: MouseEvent) => void };

type Props = {
  rowViewModel: RowViewModel;
  viewPath: (row: RowViewModel) => string;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'LinkCell',
  components: { RplLink },
  props: { rowViewModel: Object, viewPath: Function, column: Object },
  computed: {
    path() {
      return columnValue(
        this.rowViewModel.row.columns,
        `${this.column.key}_url`,
      );
    },
    text() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
  methods: {
    onClick(event) {
      if (this.path?.startsWith('/')) {
        event.preventDefault();
        this.$router.push(this.path);
      }
    },
  },
});
