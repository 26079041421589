
import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import Confirm from '@/components/Confirm.vue';
import logger from '@/logger';
import { RowViewModel } from '@/models/table.model';
import { getData } from '@/api/data';
import { Group, GroupMember } from '@/models/group.model';

type Data = { confirmDelete: boolean; working: boolean };
type Computed = {
  message: string;
  selfRemove: boolean;
  contact: GroupMember;
  group: Group;
};

interface Methods {
  onClick: (event: Event) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

type Props = { rowViewModel: RowViewModel; code: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DeleteGroupMember',
  components: { RplIcon, Confirm },
  props: { rowViewModel: Object, code: String },
  data() {
    return {
      confirmDelete: false,
      working: false,
    };
  },
  computed: {
    contact() {
      const { id } = this.rowViewModel.row;
      const contact = this.group.contacts.find((c) => c.id === id);
      if (!contact) {
        throw new Error('Contact not found');
      }
      return contact;
    },
    group() {
      return getData(this.$store.state.group.groupFromApi);
    },
    message() {
      if (this.selfRemove) {
        return 'Please confirm you would like to remove yourself from this group.\nFollowing removal, you will no longer have access to this group.';
      }
      return `Please confirm you would like to remove ${this.contact.firstName} ${this.contact.lastName} from this group`;
    },
    selfRemove() {
      return this.contact.email === this.$store.state.user.userProfile.email;
    },
  },
  methods: {
    onClick(event: Event) {
      event.stopPropagation();
      this.confirmDelete = true;
    },
    onConfirm() {
      logger.info(`Removing ${this.contact.email}`);
      this.working = true;
      this.confirmDelete = false;
      this.$store
        .dispatch('removeContactFromGroup', {
          dashboardCode: this.code,
          contact: this.contact,
          groupId: this.group.id,
          self: this.selfRemove,
        })
        .finally(() => {
          this.working = false;
        });
    },
    onCancel() {
      this.confirmDelete = false;
    },
  },
});
