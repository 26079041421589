import { ApiError, ApiErrorType, ApiStates } from './data';

export const NotFoundError = (message: string): ApiError => ({
  state: ApiStates.ERROR,
  type: ApiErrorType.NotFound,
  message,
});

export const REQUEST_CANCELLED_ERROR: ApiError = {
  state: ApiStates.ERROR,
  message: 'The request was cancelled',
  type: ApiErrorType.RequestCancelled,
};

export const ConnectorForbiddenError = (message: string): ApiError => ({
  state: ApiStates.ERROR,
  type: ApiErrorType.ConnectorForbidden,
  message,
});

export const BadGatewayError = (message: string): ApiError => ({
  state: ApiStates.ERROR,
  type: ApiErrorType.BadGateway,
  message,
});

export const UnauthorisedError = (message: string): ApiError => ({
  state: ApiStates.ERROR,
  type: ApiErrorType.Unauthorised,
  message,
});

export const DuplicateError = (message: string): ApiError => ({
  state: ApiStates.ERROR,
  type: ApiErrorType.Duplicate,
  message,
});
