import { ActionContext, Module } from 'vuex';
import { getData, getErrorMessage, isApiError } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';
import { ReportsState } from './types';
import { RootState } from '../types';

const reportmodule: Module<ReportsState, RootState> = {
  state: () => ({}),
  actions: {
    loadResourceReportData(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ReportsState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadResourceReportData',
        (client) => client.loadResourceReportData(url),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
  },
};

export default reportmodule;
