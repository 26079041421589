
import Vue from 'vue';
import authServiceFactory from '@/api/auth';
import { DASHBOARD_CODE_KEY } from '@/config/constants';

type Data = unknown;
type Computed = unknown;
type Methods = unknown;
type Props = { code?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Logout',
  props: { code: String },
  created() {
    if (this.code) {
      sessionStorage.setItem(DASHBOARD_CODE_KEY, this.code);
    }
    authServiceFactory().then((auth) => {
      auth.logout().then(() => {
        this.$store.commit('removeUser');
        this.$store.commit('setBreadcrumbs', []);
        this.$router.push({
          name: 'loggedOut',
          query: { dashboard: this.code },
        });
      });
    });
  },
});
