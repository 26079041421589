
import Vue from 'vue';
import {
  RplSearchForm,
  RplSearchResultsLayout,
  RplSearchResultsTable,
} from '@dpc-sdp/ripple-search';
import { RplCol } from '@dpc-sdp/ripple-grid';
import { RplFormEventBus, RplSelect } from '@dpc-sdp/ripple-form';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { formatDate, formatDateOrDateTime } from '@/helpers/date';
import {
  Column,
  ColumnDataType,
  ColumnFilter,
  FilterModel,
  RowViewModel,
  SortDirection,
  SortOption,
  TableViewModel,
} from '@/models/table.model';
import { VueComponent } from '@/types';
import {
  buildSortOption,
  columnActions,
  columnComponent,
  columnValue,
  getColumn,
  getSortOption,
  sortRows,
  filterRow,
  sanitiseFilterModel,
  sanitiseKey,
  EMPTY_FILTER_VALUE,
} from '@/helpers/table';
import ReloadIcon from '@/assets/sync.svg?component';
import _get from 'lodash/get';
import { formatBytes } from '@/helpers/file';
import ColumnConfig from '@/components/table/ColumnConfig.vue';
import retainState from '@/components/mixin/retainState';
import mixins from 'vue-typed-mixins';
import TotalsRow from '@/components/ripple/table/TotalsRow.vue';
import Markdown from '@/components/markdown/Markdown.vue';
import TableActions from './TableActions.vue';
import TableComponent from './TableComponent.vue';
import Clearform from './Clearform.vue';

Vue.component('tableActions', TableActions);
Vue.component('tableComponent', TableComponent);
Vue.component('field-clearform', Clearform);

type FormattedRow = {
  [id: string]: string | object;
} & { rowModel: RowViewModel };

interface Data {
  page: number;
  filterTerm?: string;
  sortOptionId?: string;
  filterModel: FilterModel;
  activeColumnFilters: ColumnFilter[];
  visibleColumns: string[];
  summableProps: { columns: Column[]; rows: RowViewModel[] };
}

interface Computed {
  defaultFilter: FilterModel;
  filteredRows: RowViewModel[];
  rows: FormattedRow[];
  count: number;
  pagedItems: FormattedRow[];
  perPage: number;
  pagination: { totalSteps: number; initialStep: number; stepsAround: number };
  displayableColumns: Column[];
  columnConfig: { label: string; key: string; component?: string }[];
  sortOptions?: SortOption[];
  handleRowClicks: boolean;
  filterable: boolean;
  searchable: boolean;
  filterForm?: unknown;
  columnOptions: { name: string; id: string }[];
  includeTotals: boolean;
}

type Methods = {
  formatByDataType: (r: RowViewModel) => {
    [id: string]: string | object;
  };
  onPagerChange: (page: number) => void;
  onChangeSortOption: (sortOptionId: string) => void;
  onClick: (event: Event) => void;
  onFilter: (filterTerm: string) => void;
  onClearFilter: () => void;
  onClearSearch: () => void;
  searchRow: (column: Column, rvm: RowViewModel, filterTerm: string) => boolean;
  onConfigureColumn: (config: { id: string; selected: boolean }) => void;
  exportToCSV: () => void;
};

type Props = {
  tableViewModel: TableViewModel;
  emitRowClicks: boolean;
  emptyMessage: string;
  initialFilterTerm?: string;
  components?: {
    name: string;
    component: () => VueComponent;
  }[];
  loading: boolean;
  defaultFilterModel?: FilterModel;
  externalSearch?: boolean;
  searchPlaceholder?: string;
};

export default mixins(retainState).extend<Data, Methods, Computed, Props>({
  name: 'DataHubTable',
  mixins: [retainState],
  props: {
    tableViewModel: Object,
    emitRowClicks: Boolean,
    emptyMessage: String,
    initialFilterTerm: String,
    components: Array,
    loading: Boolean,
    defaultFilterModel: Object,
    externalSearch: Boolean,
    searchPlaceholder: String,
  },
  components: {
    Markdown,
    ColumnConfig,
    RplSearchResultsLayout,
    RplSearchResultsTable,
    RplCol,
    RplSelect,
    RplSearchForm,
    ReloadIcon,
    RplIcon,
    RplTextLink,
  },
  data() {
    return {
      page: 1,
      filterTerm: this.initialFilterTerm || '',
      sortOptionId: undefined,
      filterModel: {},
      activeColumnFilters: [],
      visibleColumns: [],
      summableProps: { columns: [], rows: [] },
    };
  },
  computed: {
    defaultFilter() {
      if (this.defaultFilterModel) {
        return { ...sanitiseFilterModel(this.defaultFilterModel) };
      }
      if (this.tableViewModel.config.defaultFilter) {
        return {
          ...sanitiseFilterModel(this.tableViewModel.config.defaultFilter),
        };
      }
      return {};
    },
    filteredRows() {
      const columnFilters = this.activeColumnFilters;
      const searchableColumns = this.tableViewModel.config.columns.filter(
        (column: Column) => column.searchable,
      );
      return this.tableViewModel.rows.filter(
        (row) =>
          (this.externalSearch ||
            searchableColumns.length === 0 ||
            searchableColumns.some((column) =>
              this.searchRow(column, row, this.filterTerm || ''),
            )) &&
          columnFilters.every((colFilter) =>
            filterRow(colFilter.column, row.row.columns, colFilter.filterValue),
          ),
      );
    },
    rows() {
      let rows;
      if (this.sortOptionId) {
        const sortOption = getSortOption(this.sortOptions, this.sortOptionId);
        if (sortOption) {
          rows = sortRows(
            this.filteredRows,
            sortOption,
            getColumn(this.tableViewModel.config.columns, sortOption.columnKey),
          );
        } else {
          rows = this.filteredRows;
        }
      } else {
        rows = this.filteredRows;
      }

      return rows.map(
        (viewModel): FormattedRow => ({
          ...this.formatByDataType(viewModel),
          rowModel: viewModel,
        }),
      );
    },
    pagedItems() {
      return this.rows.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage,
      );
    },
    count() {
      return this.rows.length;
    },
    pagination() {
      const totalSteps = Math.ceil(this.rows.length / this.perPage);
      return {
        totalSteps: totalSteps === 1 ? 0 : totalSteps,
        initialStep: this.page,
        stepsAround: 2,
      };
    },
    displayableColumns() {
      return this.tableViewModel.config.columns.filter(
        (column) => !column.hidden && this.visibleColumns.includes(column.key),
      );
    },
    columnConfig() {
      return this.displayableColumns.map((column) => {
        const additionalProperties: { component?: string; cols?: string } = {};
        if (column.dataType === ColumnDataType.Action) {
          additionalProperties.component = 'tableActions';
        } else if (column.dataType === ColumnDataType.Component) {
          additionalProperties.component = 'tableComponent';
        } else if (column.dataType === ColumnDataType.Number) {
          additionalProperties.cols = 'center';
        }
        return {
          label: column.label,
          key: column.key,
          ...additionalProperties,
        };
      });
    },
    sortOptions() {
      return this.tableViewModel.config.columns
        .filter((col) => col.sortable)
        .flatMap((col) => [
          buildSortOption(col, SortDirection.Asc),
          buildSortOption(col, SortDirection.Desc),
        ]);
    },
    perPage() {
      return this.tableViewModel.config.pageSize || 5;
    },
    handleRowClicks() {
      return this.emitRowClicks === true;
    },
    filterable() {
      return this.tableViewModel.config.columns.some((col) => col.filterable);
    },
    searchable() {
      return this.tableViewModel.config.columns.some((col) => col.searchable);
    },
    filterForm() {
      const fields = this.tableViewModel.config.columns
        .filter(
          (col) =>
            col.filterable &&
            // allow filtering on columns hidden by config not user action
            (col.hidden || this.visibleColumns.includes(col.key)),
        )
        .map((col) => {
          switch (col.dataType) {
            case ColumnDataType.Date:
            case ColumnDataType.DateTime:
              return {
                type: 'rpldatepicker',
                range: true,
                label: col.label,
                model: col.key,
                styleClasses: 'form-group--inline',
              };
            default: {
              const uniqueColumnValues = new Set<string>();
              let hasEmpty = false;
              if (this.filterModel) {
                const filterModelForColumn = this.filterModel[col.key];
                if (filterModelForColumn) {
                  if (Array.isArray(filterModelForColumn)) {
                    filterModelForColumn.forEach((f) => {
                      if (f !== EMPTY_FILTER_VALUE) {
                        uniqueColumnValues.add(f);
                      }
                    });
                  } else {
                    uniqueColumnValues.add(filterModelForColumn);
                  }
                }
              }
              this.tableViewModel.rows.forEach((rvm) => {
                const columnData = rvm.row.columns.find(
                  (column) => column.key === col.key,
                );
                if (columnData?.value && columnData.value.trim() !== '') {
                  uniqueColumnValues.add(columnData.value);
                } else {
                  hasEmpty = true;
                }
              });
              return {
                type: 'rplselect',
                multiselect: col.multiFilterable,
                label: col.label,
                model: sanitiseKey(col.key),
                styleClasses: 'form-group--col-three',
                values: [
                  ...(col.multiFilterable ? [] : [{ id: '', value: '' }]),
                  ...(hasEmpty
                    ? [{ id: EMPTY_FILTER_VALUE, name: 'Empty' }]
                    : []),
                  ...Array.from(uniqueColumnValues)
                    .sort((a: string, b: string) =>
                      a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                      }),
                    )
                    .map((v) => ({ id: v, name: v })),
                ],
              };
            }
          }
        });
      return fields.length > 0
        ? {
            model: this.filterModel,
            tag: 'RplFieldset',
            schema: {
              groups: [
                {
                  fields,
                },
                {
                  fields: [
                    {
                      type: 'rplsubmitloader',
                      buttonText: 'Apply filters',
                      loading: false,
                      styleClasses: 'form-group--inline',
                    },
                    {
                      type: 'clearform',
                      buttonText: 'Clear search filters',
                      loading: false,
                      styleClasses: 'form-group--inline',
                      clearHandler: () => this.onClearFilter(),
                    },
                  ],
                },
              ],
            },
            formState: {},
          }
        : undefined;
    },
    columnOptions() {
      return this.tableViewModel.config.columns
        .filter((column) => !column.hidden)
        .map((col) => ({
          name: col.label || 'N/A',
          id: col.key,
          disabled: true,
        }));
    },
    includeTotals() {
      return this.tableViewModel.config.columns.some(
        (column) => column.summable,
      );
    },
  },
  methods: {
    onPagerChange(page: number) {
      this.page = page;
    },
    onChangeSortOption(sortOptionId: string) {
      this.page = 1;
      this.$emit('sort-option-changed', sortOptionId);
      this.sortOptionId = sortOptionId;
    },
    onClick(event: Event) {
      if (this.handleRowClicks && event.target) {
        const rowElement = (event.target as Element).closest('tr[data-tid]');
        if (rowElement) {
          const attribute = rowElement.attributes.getNamedItem('data-tid');
          if (attribute) {
            const rowIndex = parseInt(attribute.value.replace('row-', ''), 10);
            if (!Number.isNaN(rowIndex)) {
              this.$emit('row-clicked', this.pagedItems[rowIndex].rowModel);
            }
          }
        }
      }
    },
    formatByDataType(item) {
      const formattedItem: Record<string, string | object> = {};
      this.tableViewModel.config.columns.forEach((column: Column) => {
        const colValue = columnValue(item.row.columns, column.key);
        if (column.dataType === ColumnDataType.Action) {
          formattedItem[column.key] = {
            rowViewModel: item,
            column,
            actions: columnActions(item.row.columns, column.key),
            viewPath: this.tableViewModel.viewPath,
          };
        } else if (column.dataType === ColumnDataType.Component) {
          formattedItem[column.key] = {
            rowViewModel: item,
            column,
            component:
              columnComponent(item.row.columns, column.key) || column.component,
            viewPath: this.tableViewModel.viewPath,
          };
        } else if (colValue !== undefined) {
          if (column.dataType === ColumnDataType.Date) {
            formattedItem[column.key] = formatDate(colValue);
          } else if (column.dataType === ColumnDataType.DateTime) {
            formattedItem[column.key] = formatDateOrDateTime(colValue);
          } else if (column.dataType === ColumnDataType.FileSize) {
            formattedItem[column.key] = formatBytes(
              Number.parseInt(colValue, 10),
            );
          } else if (column.dataType === ColumnDataType.Number) {
            formattedItem[column.key] =
              Number.parseFloat(colValue).toLocaleString();
          } else {
            formattedItem[column.key] = colValue;
          }
        }
      });
      return formattedItem;
    },
    onFilter(filterTerm) {
      this.page = 1;
      this.$emit('search', filterTerm);
      this.filterTerm = filterTerm;
      this.activeColumnFilters = this.tableViewModel.config.columns
        .filter((column: Column) => column.filterable)
        .filter(
          (column: Column) =>
            _get(this.filterModel, sanitiseKey(column.key), '')
              .toString()
              .trim() !== '',
        )
        .map((column) => ({
          filterValue: _get(this.filterModel, sanitiseKey(column.key), ''),
          column,
        }));
    },
    onClearFilter() {
      Object.keys(this.filterModel).forEach((key) => {
        const value = this.filterModel[key];
        if (Array.isArray(value)) {
          // Hackery required to overcome a bug in ripple - setting to undefined clears the date range fields, subsequently setting to empty array resets the filter count
          this.filterModel[key] = undefined;
          setTimeout(() => {
            this.filterModel[key] = [];
          }, 0);
        } else {
          this.filterModel[key] = undefined;
        }
      });
      this.filterModel = { ...this.filterModel };
      this.activeColumnFilters = [];
      this.$emit('clear-filters');
    },
    onClearSearch() {
      this.onFilter('');
    },
    searchRow(column, rvm, filterTerm) {
      let valueToFilter = columnValue(rvm.row.columns, column.key);
      if (valueToFilter && column.dataType === ColumnDataType.Date) {
        valueToFilter = formatDate(valueToFilter.toString());
      } else if (valueToFilter && column.dataType === ColumnDataType.DateTime) {
        valueToFilter = formatDateOrDateTime(valueToFilter.toString());
      }
      // get the value from the column filter field
      const filterValue = new RegExp(filterTerm.trim(), 'i');
      return filterValue.test(valueToFilter || '');
    },
    onConfigureColumn(config) {
      if (config.selected) {
        this.visibleColumns.push(config.id);
      } else {
        this.visibleColumns.splice(this.visibleColumns.indexOf(config.id), 1);
      }
    },
    exportToCSV() {
      const dataColumns = this.tableViewModel.config.columns.filter(
        (col) =>
          !col.hidden &&
          col.dataType !== ColumnDataType.Action &&
          col.label &&
          this.visibleColumns.includes(col.key),
      );
      const data = [
        dataColumns.map((col) => col.label),
        ...this.rows.map((row) =>
          dataColumns.map((col) => {
            if (col.dataType === ColumnDataType.Component) {
              return columnValue(row.rowModel.row.columns, col.key) || '';
            }
            return row[col.key] || '';
          }),
        ),
      ];
      const blob = new Blob(
        [data.map((row) => row.map((val) => `"${val}"`).join(',')).join('\n')],
        { type: 'text/csv;charset=utf-8,' },
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${(this.tableViewModel.config.label || 'report')
        .toLowerCase()
        .replaceAll(' ', '_')}.csv`;
      link.click();
      link.remove();
    },
  },
  watch: {
    // Handle scenario where last item on a page is removed
    pagedItems(items: FormattedRow[]) {
      if (items.length === 0 && this.page !== 1) {
        this.page -= 1;
      }
    },
    rows(rows: FormattedRow[]) {
      this.$emit(
        'rowschanged',
        rows.map((r) => r.rowModel),
      );
      this.summableProps.columns = this.displayableColumns;
      this.summableProps.rows = this.filteredRows;
    },
    defaultFilter() {
      this.filterModel = {
        ...this.filterModel,
        ...sanitiseFilterModel(this.defaultFilter),
      };
      this.onFilter(this.filterTerm || '');
    },
  },
  mounted() {
    if (this.$refs.table) {
      const tableComponent = this.$refs.table as Vue;
      const rows = tableComponent.$el.querySelectorAll('tbody tr');
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows[i] as HTMLElement;
        row.setAttribute('tabindex', '0');
        row.addEventListener('keydown', (evt: Event) => {
          const keyboardEvt = evt as KeyboardEvent;
          const focusedElement = document.activeElement;
          const isInputField = focusedElement?.tagName === 'INPUT';
          if (
            !isInputField &&
            (keyboardEvt.key === 'Enter' || keyboardEvt.key === ' ')
          ) {
            keyboardEvt.stopPropagation();
            keyboardEvt.preventDefault();
            row.click();
          }
        });
      }

      // manually mount a totals footer row
      if (this.includeTotals) {
        const totalsRow = new TotalsRow() as Vue & { _props: unknown };
        // eslint-disable-next-line no-underscore-dangle
        totalsRow._props = this.summableProps;
        const table = tableComponent.$el;
        if (table) {
          const tfoot = document.createElement('tfoot');
          table.append(tfoot);
          totalsRow.$mount();
          tfoot.append(totalsRow.$el);
        }
      }
    }
  },
  created() {
    if (this.components) {
      this.components.forEach((c) => Vue.component(c.name, c.component));
    }
    const { defaultSortOption } = this.tableViewModel.config;
    if (defaultSortOption) {
      this.sortOptionId = `${defaultSortOption.key}${
        defaultSortOption.direction === 'Ascending'
          ? SortDirection.Asc
          : SortDirection.Desc
      }`;
    }
    RplFormEventBus.$on('clearform', () => {
      this.filterModel = {};
      this.activeColumnFilters = [];
    });
    if (Object.keys(this.defaultFilter).length) {
      this.filterModel = this.defaultFilter;
      this.onFilter(this.filterTerm || '');
    }
    this.visibleColumns = this.columnOptions.map((opt) => opt.id);
    this.configureRetainState(
      this.tableViewModel.config.retainLocal,
      this.tableViewModel.config.retainSession,
    );
  },
});
