import { ActionContext, Module } from 'vuex';
import { getData, isApiError } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';
import {
  DashboardDto,
  PageDto,
  WidgetDefinitionDto,
  WidgetDto,
} from '@/models/admin.model';
import { AdminState } from './types';
import { RootState } from '../types';

const adminmodule: Module<AdminState, RootState> = {
  state: () => ({}),
  actions: {
    loadDashboardSummaries({
      commit,
      rootState,
      rootGetters,
    }: ActionContext<AdminState, RootState>) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadDashboardSummaries',
        (client) => client.loadDashboardSummaries(),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    createDashboard(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: {
        code: string;
        tenant: string;
        managed: boolean;
        public: boolean;
        headerBgColour?: string;
        headerImgColour?: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'createDashboard',
        (client) =>
          client.createDashboard(
            args.code,
            args.tenant,
            args.managed,
            args.public,
            args.headerBgColour,
            args.headerImgColour,
          ),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
      });
    },
    updateDashboard(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      dashboard: DashboardDto,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateDashboard',
        (client) => client.updateDashboard(dashboard),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
      });
    },
    deleteDashboard(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      code: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deleteDashboard',
        (client) => client.deleteDashboard(code),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
      });
    },
    exportDashboard(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      code: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'exportDashboard',
        (client) => client.exportDashboard(code),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    adminLoadDashboard(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      dashboardCode: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadDashboard',
        (client) => client.loadDashboard(dashboardCode),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    createPage(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: { page: PageDto; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'createPage',
        (client) => client.createPage(args.page, args.dashboardCode),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    updatePage(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: { page: PageDto; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updatePage',
        (client) => client.updatePage(args.page, args.dashboardCode),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    deletePage(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: { dashboardCode: string; pageCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deletePage',
        (client) => client.deletePage(args.dashboardCode, args.pageCode),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    loadWidgetDefinitions({
      commit,
      rootState,
      rootGetters,
    }: ActionContext<AdminState, RootState>) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadWidgetDefinitions',
        (client) => client.loadWidgetDefinitions(),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    loadWidgetDefinition(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      type: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadWidgetDefinition',
        (client) => client.loadWidgetDefinition(type),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    createWidgetDefinition(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      widgetDefinition: WidgetDefinitionDto,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'createWidgetDefinition',
        (client) => client.createWidgetDefinition(widgetDefinition),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    updateWidgetDefinition(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      widgetDefinition: WidgetDefinitionDto,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateWidgetDefinition',
        (client) => client.updateWidgetDefinition(widgetDefinition),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    deleteWidgetDefinition(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      type: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deleteWidgetDefinition',
        (client) => client.deleteWidgetDefinition(type),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    createWidget(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: { widget: WidgetDto; pageCode: string; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'createWidget',
        (client) =>
          client.createWidget(args.widget, args.pageCode, args.dashboardCode),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    updateWidget(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: { widget: WidgetDto; pageCode: string; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateWidget',
        (client) =>
          client.updateWidget(args.widget, args.pageCode, args.dashboardCode),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    deleteWidget(
      { commit, rootState, rootGetters }: ActionContext<AdminState, RootState>,
      args: { widgetCode: string; pageCode: string; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deleteWidget',
        (client) =>
          client.deleteWidget(
            args.widgetCode,
            args.pageCode,
            args.dashboardCode,
          ),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
  },
};

export default adminmodule;
