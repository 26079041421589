import { ActionContext, Module } from 'vuex';
import { ColourRegistryState } from '@/store/modules/types';
import stc from 'string-to-color';
import { ValueColour } from '@/models/chart.model';
import { RootState } from '../types';

const colourRegistryModule: Module<ColourRegistryState, RootState> = {
  state: () => ({
    registry: {},
    defaultColours: [],
  }),
  getters: {
    getColour: (state) => (value: string) => {
      const colourMapping = state.registry[value];
      if (colourMapping) {
        return colourMapping;
      }
      return stc(value);
    },
  },
  mutations: {
    registerColour(state, valueColour: ValueColour) {
      state.registry = {
        ...state.registry,
        [valueColour.value]: valueColour.colour,
      };
    },
    setDefaultColours(state, defaultColours: ValueColour[]) {
      state.defaultColours = defaultColours;
    },
    resetValueColours(state) {
      state.registry = {};
      state.defaultColours.forEach((valueColour) => {
        state.registry[valueColour.value] = valueColour.colour;
      });
    },
  },
  actions: {
    registerColours(
      { commit }: ActionContext<ColourRegistryState, RootState>,
      valueColours: ValueColour[],
    ) {
      commit('setDefaultColours', valueColours);
      valueColours.forEach((valueColour) =>
        commit('registerColour', valueColour),
      );
    },
  },
};

export default colourRegistryModule;
