
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import AddMemberAction from '@/components/table/actions/AddMemberAction.vue';
import AddRoleToUserAction from '@/components/table/actions/role/AddRoleToUserAction.vue';
import BatchDownloadAction from '@/components/table/actions/BatchDownloadAction.vue';
import DeleteAttachmentAction from '@/components/table/actions/DeleteAttachmentAction.vue';
import EditAttachmentAction from '@/components/table/actions/EditAttachmentAction.vue';
import DeleteGroupAction from '@/components/table/actions/group/DeleteGroupAction.vue';
import DeleteTemplateAction from '@/components/table/actions/DeleteTemplateAction.vue';
import DownloadAction from '@/components/table/actions/DownloadAction.vue';
import DownloadAttachmentAction from '@/components/table/actions/DownloadAttachmentAction.vue';
import DownloadTemplateResourceAction from '@/components/table/actions/DownloadTemplateResourceAction.vue';
import MakeChildAction from '@/components/table/actions/relationship/MakeChildAction.vue';
import MakeParentAction from '@/components/table/actions/relationship/MakeParentAction.vue';
import MakeRelatedAction from '@/components/table/actions/relationship/MakeRelatedAction.vue';
import MergeGroupAction from '@/components/table/actions/group/MergeGroupAction.vue';
import OpenAttachmentAction from '@/components/table/actions/OpenAttachmentAction.vue';
import RemoveRelationshipAction from '@/components/table/actions/relationship/RemoveRelationshipAction.vue';
import RemoveRoleFromUserAction from '@/components/table/actions/role/RemoveRoleFromUserAction.vue';
import RemoverUserFromGroupAction from '@/components/table/actions/group/RemoverUserFromGroupAction.vue';
import CopyAttachmentAction from '@/components/table/actions/CopyAttachmentAction.vue';

Vue.component('add-member-action', AddMemberAction);
Vue.component('add-role-to-user-action', AddRoleToUserAction);
Vue.component('batch-download-action', BatchDownloadAction);
Vue.component('edit-attachment-action', EditAttachmentAction);
Vue.component('delete-attachment-action', DeleteAttachmentAction);
Vue.component('delete-template-action', DeleteTemplateAction);
Vue.component('delete-group-action', DeleteGroupAction);
Vue.component('download-action', DownloadAction);
Vue.component('download-attachment-action', DownloadAttachmentAction);
Vue.component(
  'download-template-resource-action',
  DownloadTemplateResourceAction,
);
Vue.component('make-child-action', MakeChildAction);
Vue.component('make-parent-action', MakeParentAction);
Vue.component('make-related-action', MakeRelatedAction);
Vue.component('merge-group-action', MergeGroupAction);
Vue.component('open-attachment-action', OpenAttachmentAction);
Vue.component('remove-relationship-action', RemoveRelationshipAction);
Vue.component('remove-role-from-user-action', RemoveRoleFromUserAction);
Vue.component('remove-user-from-group-action', RemoverUserFromGroupAction);
Vue.component('copy-attachment-action', CopyAttachmentAction);

type Data = {};

type Computed = {};

type Methods = {};

type Props = {
  rowViewModel: RowViewModel;
  viewPath: (row: RowViewModel) => string;
  column: Column;
  actions: string[];
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TableActions',
  components: {},
  props: {
    rowViewModel: Object,
    viewPath: Function,
    column: Object,
    actions: [],
  },
});
