
import Vue from 'vue';

type Data = unknown;
type Computed = unknown;
type Methods = unknown;
type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'LoggedOut',
  mounted() {
    this.$store.commit('setHeaderText', 'Thank you!');
    this.$store.commit('setSubText', '');
  },
});
