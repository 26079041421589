import { ActionContext, Module } from 'vuex';
import auth from '@/api/auth';
import {
  DataFromApi,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
} from '@/api/data';
import { UserData, UserProfile } from '@/models/user.model';
import { UserState } from './types';
import { RootState } from '../types';
import { recordApiCall } from './apiCalls';

const usermodule: Module<UserState, RootState> = {
  state: () => ({
    userProfile: undefined,
  }),
  getters: {
    isLoggedIn: (state) => !!state.userProfile,
    userDisplayName: (state) =>
      state.userProfile
        ? `${state.userProfile.firstName} ${state.userProfile.lastName}`
        : '',
    userExternalId: (state) => state.userProfile?.externalId,
  },
  mutations: {
    setUser(state, user: UserProfile) {
      state.userProfile = { ...user, data: undefined };
    },
    setUserData(state, data: UserData) {
      if (state.userProfile) {
        state.userProfile.data = data;
      }
    },
    removeUser(state) {
      state.userProfile = undefined;
    },
  },
  actions: {
    async changePassword(
      context: ActionContext<UserState, RootState>,
      args: { currentPassword: string; newPassword: string },
    ) {
      const authService = await auth();
      return authService.changePassword(args.currentPassword, args.newPassword);
    },
    addRoleToUser(
      { commit, rootState, rootGetters }: ActionContext<UserState, RootState>,
      args: {
        url: string;
        body: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addRoleToUser',
        (client) => client.addRoleToUser(args.url, args.body),
      ).then((response) => {
        if (!isApiError(response)) {
          return response;
        }
        throw new Error(getErrorMessage(response));
      });
    },
    removeRoleFromUser(
      { commit, rootState, rootGetters }: ActionContext<UserState, RootState>,
      args: {
        url: string;
        body: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeRoleFromUser',
        (client) => client.removeRoleFromUser(args.url, args.body),
      ).then((response) => {
        if (!isApiError(response)) {
          return response;
        }
        throw new Error(getErrorMessage(response));
      });
    },
    updateUserProfile(
      {
        commit,
        dispatch,
        rootGetters,
        rootState,
      }: ActionContext<UserState, RootState>,
      args: { profile: UserProfile; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateUserProfile',
        (client) => client.updateUserProfile(args.profile, args.dashboardCode),
      ).then(async (response) => {
        if (!isApiError(response)) {
          dispatch('loadUserProfile', args.dashboardCode);
        } else {
          throw new Error(getErrorMessage(response));
        }
      });
    },
    findUser(
      { commit, rootGetters, rootState }: ActionContext<UserState, RootState>,
      args: { email: string; dashboardCode: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'findUser',
        (client) => client.findUser(args.email, args.dashboardCode),
      );
    },
    loadUserProfile(
      { state, dispatch, commit }: ActionContext<UserState, RootState>,
      dashboardCode: string,
    ) {
      dispatch('findUser', {
        email: state.userProfile?.email,
        dashboardCode,
      }).then((response: DataFromApi<UserProfile>) => {
        // findUser returns an empty object if user not found, so ensure firstName and lastName are present in the response
        if (
          !isApiError(response) &&
          hasData(response) &&
          'firstName' in getData(response) &&
          'lastName' in getData(response)
        ) {
          const userProfile = getData(response);
          commit('setUserData', userProfile.data);
        }
      });
    },
  },
};

export default usermodule;
