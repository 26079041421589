import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  AuthenticationDetails,
  CookieStorage,
} from 'amazon-cognito-identity-js';
import logger from '@/logger';
import defaultConfigPromise from '@/config';
import { CognitoConfig, Configuration } from '@/config/types';
import { User } from '@/models/user.model';
import { REDIRECT_ROUTE_KEY } from '@/config/constants';
import { UserTokenService } from './types';

class TokenService implements UserTokenService {
  config: CognitoConfig;

  constructor(config: CognitoConfig) {
    this.config = config;
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve) =>
      this.getAuthenticatedUser()
        .then(() => resolve(true))
        .catch(() => resolve(false)),
    );
  }

  getCognitoUser(): CognitoUser | undefined {
    if (this.config.userPoolId && this.config.clientId) {
      const userPool = new CognitoUserPool({
        UserPoolId: this.config.userPoolId,
        ClientId: this.config.clientId,
        Storage: new CookieStorage({ domain: this.config.domain }),
      });
      return userPool.getCurrentUser() || undefined;
    }
    return undefined;
  }

  getAuthenticatedUser(): Promise<CognitoUser> {
    return new Promise((resolve) => {
      const user = this.getCognitoUser();
      if (!user) {
        throw new Error('Cognito user not found');
      }
      user.getSession((sessionErr: unknown) => {
        if (sessionErr) {
          throw new Error('User not authenticated');
        }
        resolve(user);
      });
    });
  }

  async getUser(): Promise<User | undefined> {
    try {
      const cognitoUser = this.getCognitoUser();
      if (cognitoUser) {
        return new Promise((resolve) => {
          cognitoUser.getSession((sessionErr: unknown) => {
            if (sessionErr) {
              resolve(undefined);
              return;
            }
            cognitoUser.getUserAttributes((err, attributes) => {
              const user: User = {
                email: '',
                firstName: '',
                lastName: '',
                externalId: '',
              };
              if (attributes) {
                attributes.forEach((attribute) => {
                  if (attribute.getName() === 'given_name') {
                    user.firstName = attribute.getValue();
                  } else if (attribute.getName() === 'family_name') {
                    user.lastName = attribute.getValue();
                  } else if (attribute.getName() === 'email') {
                    user.email = attribute.getValue();
                  } else if (attribute.getName() === 'sub') {
                    user.externalId = attribute.getValue();
                  }
                });
                resolve(user);
              }
            });
          });
        });
      }
    } catch (e) {
      logger.error(e);
    }
    return undefined;
  }

  redirectToLogin(rootPath: string) {
    logger.debug('Redirecting to login');
    const redirect = sessionStorage.getItem(REDIRECT_ROUTE_KEY) || rootPath;
    window.location.href = `${
      this.config.authService
    }/login?redirect_uri=${encodeURIComponent(
      `${this.config.redirectBase}${redirect}`,
    )}`;
  }

  logout(): Promise<void> {
    const user = this.getCognitoUser();
    if (user) {
      return new Promise((resolve) => user.signOut(() => resolve(undefined)));
    }
    return Promise.resolve();
  }

  getAccessToken(): Promise<string | undefined> {
    return new Promise((resolve) => {
      const user = this.getCognitoUser();
      if (user) {
        user.getSession((err: unknown, session: CognitoUserSession) => {
          if (err) {
            resolve(undefined);
            return;
          }
          resolve(session.getAccessToken().getJwtToken());
        });
      } else {
        resolve(undefined);
      }
    });
  }

  async changePassword(
    currentPassword: string,
    newPassword: string,
  ): Promise<void> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(currentPassword, newPassword, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });
  }

  async associateSoftwareToken(): Promise<string> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.associateSoftwareToken({
        associateSecretCode: resolve,
        onFailure: reject,
      });
    });
  }

  async refreshUserSession(): Promise<void> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.getSession(
        (sessionError: unknown, session: CognitoUserSession) => {
          if (sessionError) {
            reject(sessionError);
          } else {
            cognitoUser.refreshSession(
              session.getRefreshToken(),
              (refreshError, newSession) => {
                if (refreshError) {
                  reject(refreshError);
                } else {
                  cognitoUser.setSignInUserSession(newSession);
                  resolve();
                }
              },
            );
          }
        },
      );
    });
  }

  async enableMFA(): Promise<string> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.setUserMfaPreference(
        null,
        { PreferredMfa: true, Enabled: true },
        (error, result) => {
          if (error) {
            reject(error);
          } else {
            this.refreshUserSession()
              .then(() => resolve(result || ''))
              .catch((e) => reject(e));
          }
        },
      );
    });
  }

  async disableMFA(): Promise<string> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.setUserMfaPreference(
        null,
        { PreferredMfa: false, Enabled: false },
        (error, result) => {
          if (error) {
            reject(error);
          } else {
            this.refreshUserSession()
              .then(() => resolve(result || ''))
              .catch((e) => reject(e));
          }
        },
      );
    });
  }

  async isMFAEnabled(): Promise<boolean> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.getUserData((error, data) => {
        if (error) {
          reject(error);
        } else if (data) {
          const { PreferredMfaSetting } = data;
          resolve(!!PreferredMfaSetting);
        }
      });
    });
  }

  async verifyMfaCode(code: string): Promise<boolean> {
    const cognitoUser = await this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      cognitoUser.verifySoftwareToken(code, 'your authenticator app', {
        onSuccess: () => resolve(true),
        onFailure: reject,
      });
    });
  }

  async checkPassword(password: string): Promise<boolean> {
    const cognitoUser = await this.getAuthenticatedUser();
    const user = await this.getUser();
    const authDetails = new AuthenticationDetails({
      Username: user?.email || '',
      Password: password,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: () => resolve(true),
        totpRequired: () => resolve(true),
        onFailure: reject,
      });
    });
  }
}

const configureAuthService = (
  configPromise: () => Promise<Configuration> = defaultConfigPromise,
): Promise<UserTokenService> =>
  configPromise()
    .then((cfg) => {
      logger.debug(`Creating TokenService for with config:`, cfg.cognito);

      return new TokenService(cfg.cognito);
    })
    .catch((err) => {
      logger.error('unable to create auth service: ', err);
      throw err;
    });

export default configureAuthService;
