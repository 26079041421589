
import Vue from 'vue';
import { DataFromApi, isApiError, isLoading } from '@/api/data';
import WidgetLoader from './WidgetLoader.vue';

type Data = {};
type Computed = {
  loading: boolean;
  error: boolean;
};
type Methods = {};
type Props = { dataFromApi: DataFromApi<unknown>; label: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ApiData',
  components: { WidgetLoader },
  props: { dataFromApi: Object, label: String },
  computed: {
    loading() {
      return isLoading(this.dataFromApi);
    },
    error() {
      return isApiError(this.dataFromApi);
    },
  },
});
