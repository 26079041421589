import Vue from 'vue';
import logger from '@/logger';

type Data = {
  retainLocal?: boolean;
  retainSession?: boolean;
};

type Computed = {};

type Methods = {
  configureRetainState: (
    retainLocal?: boolean,
    retainSession?: boolean,
  ) => void;
};

type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AbstractRetainState',
  data() {
    return {
      retainLocal: false,
      retainSession: false,
    };
  },
  methods: {
    configureRetainState(retainLocal, retainSession) {
      if ((retainSession || retainLocal) && !this.$vnode.key) {
        logger.error(
          'Retain state only works when a component key has been specified',
        );
      }
      if (this.$vnode.key) {
        let data;
        if (retainLocal) {
          data = localStorage.getItem(this.$vnode.key.toString());
        } else if (retainSession) {
          data = sessionStorage.getItem(this.$vnode.key.toString());
        }
        if (data) {
          Object.assign(this.$data, JSON.parse(data));
        }
      }
      this.retainLocal = retainLocal;
      this.retainSession = retainSession;
    },
  },
  watch: {
    $data: {
      handler() {
        if (this.$vnode.key) {
          if (this.retainLocal) {
            localStorage.setItem(
              this.$vnode.key.toString(),
              JSON.stringify(this.$data),
            );
          } else if (this.retainSession) {
            sessionStorage.setItem(
              this.$vnode.key.toString(),
              JSON.stringify(this.$data),
            );
          }
        }
      },
      deep: true,
    },
  },
});
