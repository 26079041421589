import handlebars from 'handlebars';

export type TemplateString =
  | { success: true; value: string }
  | { success: false };

export default function templateString(
  templateStr: string,
  values: Record<string, unknown>,
): TemplateString {
  try {
    const template = templateStr.replace(/<%=/g, '{{').replace(/%>/g, '}}');

    const compiledTemplate = handlebars.compile(template ?? '', {
      strict: true,
    });
    return { success: true, value: compiledTemplate(values ?? {}) };
  } catch (e) {
    return { success: false };
  }
}

export function templateStringOrEmpty(
  templateStr: string,
  values: Record<string, unknown>,
): string {
  const result = templateString(templateStr, values);
  if (!result.success) {
    return '';
  }
  return result.value;
}
