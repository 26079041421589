import restClient from '@/api/restClient';
import appEngineApi from '@/api/appEngineApi';
import axios from 'axios';
import getConfig from '@/config';
import type { IdResponse } from '@/models/resource.model';
import { Group, GroupSearchResponse } from '@/models/app-engine/group.model';
import {
  ListEmailsResponse,
  EmailResponse,
  DownloadAttachmentUrlResource,
  CommentResponse,
  ListCommentsResponse,
} from './types';

export async function fetchAttachments(resourceId: string) {
  return appEngineApi.getAttachments(resourceId);
}

export async function fetchResource<T>(resourceId: string) {
  return appEngineApi.getResource<T>(resourceId);
}

export async function fetchEmailGroups(
  groupIds: string[] | undefined,
): Promise<Group[]> {
  const config = await getConfig();
  return restClient
    .get<GroupSearchResponse>(
      `${config.appEngineApiUrl}/groups?data.expand=toEmailAddresses,ccEmailAddresses,bccEmailAddresses&groupIds=${groupIds}`,
    )
    .then((r) => r.data.results);
}

export async function moveEmailToResource(
  emailId: string,
  originResourceId: string,
  targetResourceId: string,
): Promise<IdResponse> {
  const config = await getConfig();
  return restClient
    .post<IdResponse, { resourceId: string }>(
      `${config.appEngineApiUrl}/resources/${originResourceId}/emails/${emailId}/move`,
      { resourceId: targetResourceId },
    )
    .then((r) => r.data);
}

export async function fetchResourceEmails(
  resourceId: string,
): Promise<EmailResponse[]> {
  const config = await getConfig();
  return restClient
    .get<ListEmailsResponse>(
      `${config.appEngineApiUrl}/resources/${resourceId}/emails`,
    )
    .then((r) => r.data.emails);
}

export async function downloadAttachment(
  resourceId: string,
  attachmentId: string,
): Promise<Blob> {
  const config = await getConfig();
  const response = await restClient
    .get<DownloadAttachmentUrlResource>(
      `${config.appEngineApiUrl}/resources/${resourceId}/attachments/${attachmentId}/download-url`,
    )
    .then((r) => r.data);

  const data = await axios.request({
    method: 'get',
    responseType: 'arraybuffer',
    url: response.url,
  });

  return new Blob([data.data]);
}

export async function deleteComment(
  resourceId: string,
  commentId: string,
): Promise<IdResponse> {
  const config = await getConfig();
  return restClient
    .delete<IdResponse>(
      `${config.appEngineApiUrl}/resources/${resourceId}/comments/${commentId}`,
    )
    .then((r) => r.data);
}

export async function deleteEmail(
  resourceId: string,
  emailId: string,
): Promise<IdResponse> {
  const config = await getConfig();
  return restClient
    .delete<IdResponse>(
      `${config.appEngineApiUrl}/resources/${resourceId}/emails/${emailId}`,
    )
    .then((r) => r.data);
}

export async function fetchResourceComments(
  resourceId: string,
): Promise<CommentResponse[]> {
  const config = await getConfig();
  return restClient
    .get<ListCommentsResponse>(
      `${config.appEngineApiUrl}/resources/${resourceId}/comments?limit=200`,
    )
    .then((r) => r.data.comments);
}
