
import Vue from 'vue';
import { ApiNeverLoaded, DataFromApi, getData, hasData } from '@/api/data';
import { Dashboard } from '@/models/dashboard.model';
import ApiData from '@/components/ApiData.vue';
import logger from '@/logger';
import { paths } from '@/router/paths';

type Data = {};
type Computed = {
  dataFromApi: DataFromApi<Dashboard>;
  dashboard?: Dashboard;
};
type Methods = {
  loadDashboard: () => void;
};
type Props = { code: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Dashboard',
  components: { ApiData },
  props: { code: String },
  data() {
    return {};
  },
  computed: {
    dataFromApi() {
      return this.$store.state.dashboard.dashboardFromApi;
    },
    dashboard() {
      return hasData(this.dataFromApi) ? getData(this.dataFromApi) : undefined;
    },
  },
  created() {
    this.loadDashboard();
  },
  methods: {
    loadDashboard() {
      if (this.$route.path.startsWith('/public')) {
        this.$store.dispatch('loadPublicDashboard', this.code);
      } else {
        this.$store.dispatch('loadDashboard', this.code);
      }
    },
  },
  watch: {
    dashboard(dashboard: Dashboard) {
      if (dashboard) {
        const defaultPage = dashboard.pages?.find(
          (page) => page.code === dashboard.defaultPageCode,
        );
        if (defaultPage?.breadCrumb) {
          this.$store.commit('setRootBreadCrumb', {
            text: defaultPage.breadCrumb,
            url: paths.page(dashboard.code, defaultPage.code),
          });
        }
        if (
          !this.$route.params.pageCode &&
          defaultPage &&
          (this.$route.name === 'dashboard' ||
            this.$route.name === 'public-dashboard')
        ) {
          this.$router
            .replace({
              name: 'page',
              params: { pageCode: defaultPage.code },
              query: this.$route.query,
            })
            .catch(() => logger.debug('ignore duplicate navigation'));
        }
      }
    },
    code() {
      this.$store.commit('setDashboard', ApiNeverLoaded);
      this.loadDashboard();
    },
  },
});
