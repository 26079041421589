/**
 * This returns path helpers.
 *
 */
import { pathFragments } from '@/router/internal/paths';

const basePath = `/`;
export const paths = {
  // The paths here must be kept in sync with the construction in routes.ts
  base: () => basePath,
  // login
  loggedOut: () => `${basePath}${pathFragments.loggedOut()}`,
  login: () => `${basePath}${pathFragments.login()}`,
  logout: () => `${basePath}${pathFragments.logout()}`,
  dashboard: (code: string) => `${basePath}${pathFragments.dashboard(code)}`,
  page: (dashboardCode: string, pageCode: string) =>
    `${basePath}${pathFragments.dashboard(dashboardCode)}/${pathFragments.page(
      pageCode,
    )}`,
  groups: () => `${basePath}${pathFragments.groups()}`,
  group: (groupId: string) => `${basePath}${pathFragments.group(groupId)}`,
  addGroupMember: (groupId: string) =>
    `${basePath}${pathFragments.addGroupMember(groupId)}`,
  editGroupDetails: (groupId: string) =>
    `${basePath}${pathFragments.editGroupDetails(groupId)}`,
  changePassword: () => `${basePath}${pathFragments.changePassword()}`,
  mfa: () => `${basePath}${pathFragments.mfa()}`,
  profile: () => `${basePath}${pathFragments.profile()}`,
};

export default paths;
