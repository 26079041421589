import { RootState, TenantsState } from '@/store/types';
import { ActionContext, Module } from 'vuex';
import { TableState } from '@/store/modules/types';
import { recordApiCall } from '@/store/modules/apiCalls';
import { isApiError } from '@/api/data';
import { Tenant } from '@/api/types';

const tenants: Module<TenantsState, RootState> = {
  state: () => ({
    tenants: [],
  }),
  mutations: {
    setTenants(state, newTenants) {
      state.tenants = newTenants.results.map((t: Tenant) => ({
        id: t.value,
        name: t.value,
      }));
    },
  },
  actions: {
    loadTenants(
      { commit, rootState, rootGetters }: ActionContext<TableState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadTenants',
        (client) => client.loadTenants(url),
      ).then((response) => {
        if (!isApiError(response)) {
          commit('setTenants', response.data);
          return;
        }
        throw new Error(`Failed to load tenants. ${response.message}`);
      });
    },
  },
};

export default tenants;
