export enum UploadStatus {
  Queued = 'Queued',
  Error = 'Error',
  Rejected = 'Rejected',
  Uploading = 'Uploading',
  Processing = 'Processing',
  InvalidType = 'InvalidType',
  Complete = 'Complete',
}

export interface Upload {
  fileId?: number;
  file: File;
  status: UploadStatus;
  message?: string;
  progress?: number;
  cancelAction?: () => void;
  details?: BatchUploadDetails;
}

export interface UploadProgress {
  progress: number;
  upload: Upload;
}

export interface UploadUpdate {
  fileId: number;
  status: UploadStatus;
  message?: string;
  progress?: number;
  cancelAction?: () => void;
  upload: Upload;
  details?: BatchUploadDetails;
}

export enum BatchType {
  Upload = 'Upload',
  Delete = 'Delete',
}

export enum BatchStatus {
  Open = 'Open',
  Closed = 'Closed',
  Complete = 'Complete',
  Error = 'Error',
}

export interface Batch {
  status: BatchStatus;
  batchCode: string;
  files?: BatchUploadDetails[];
}

export enum BatchUploadStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum ApplicationMessageType {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
}

export interface ApplicationMessage {
  type: ApplicationMessageType;
  value: string;
  field: string;
}

export interface ApplicationDetails {
  applicationId: string;
  messages: ApplicationMessage[];
}

export interface UploadResponse {
  fileId: string;
  status: BatchUploadStatus;
  totalSubmissions: number;
}

export interface BatchUploadDetails {
  fileId?: string;
  type?: BatchType;
  status?: BatchUploadStatus;
  rejectedReason?: string;
  totalApplications?: number;
  applicationsWithErrors?: number;
  applications?: ApplicationDetails[];
  totalErrors?: number;
}
