import Vue from 'vue';
import { WidgetType, WidgetProps } from '@/components/widgets/types';
import { VueComponent } from '@/types';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options';
import mixins from 'vue-typed-mixins';
import Loading from '@/components/shared/WidgetLoading.vue';

export const registerAsyncWidget = (type: WidgetType, path: string) => {
  Vue.component(type, () => ({
    component: import(
      /* webpackChunkName: "[request]" */ `@/components/widgets/${path}`
    ),
    loading: Loading,
  }));
};

export const Widget = {
  extend: <
    T extends WidgetType,
    Data,
    Methods,
    Computed,
    Props extends { properties?: WidgetProps<T> },
    SetupBindings = unknown,
  >(
    options?: ThisTypedComponentOptionsWithRecordProps<
      Vue,
      Data,
      Methods,
      Computed,
      Props,
      SetupBindings,
      {},
      {}
    > & { type: T },
  ) => Vue.extend(options),
};

export const WidgetMixin = {
  extend: <
    T extends WidgetType,
    Data,
    Methods,
    Computed,
    Props extends { properties?: WidgetProps<T> },
    SetupBindings = unknown,
  >(
    mixin: VueComponent,
    options?: ThisTypedComponentOptionsWithRecordProps<
      Vue,
      Data,
      Methods,
      Computed,
      Props,
      SetupBindings,
      {},
      {}
    > & { type: T },
  ) => mixins(mixin).extend(options),
};
