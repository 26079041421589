import { ActionContext, Module } from 'vuex';
import {
  IdResponse,
  ListResourceAttachmentResponse,
} from '@/models/resource.model';
import { ApiResponse, getData, getErrorMessage, isApiError } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';
import { RootState } from '../types';
import { ResourceAttachmentState } from './types';

const resourceAttachmentModule: Module<ResourceAttachmentState, RootState> = {
  state: () => ({}),
  actions: {
    getResourceAttachments(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceAttachmentState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getResourceAttachments',
        (client) => client.getResourceAttachments(url),
      ).then((response: ApiResponse<ListResourceAttachmentResponse>) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
    deleteResourceAttachment(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceAttachmentState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deleteResourceAttachment',
        (client) => client.deleteResourceAttachment(url),
      ).then((response: ApiResponse<IdResponse>) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
  },
};

export default resourceAttachmentModule;
