import { ActionContext, Module } from 'vuex';
import { getData, isApiError } from '@/api/data';
import { Upload, UploadStatus } from '@/models/data.model';
import { formatBytes } from '@/helpers/file';
import { dataHubClient } from '@/api';
import { DataState } from './types';
import { RootState } from '../types';

const datamodule: Module<DataState, RootState> = {
  state: () => ({
    uploads: [],
  }),
  mutations: {
    addUpload(state, upload: Upload) {
      state.uploads.push(upload);
    },
    setUploadErrorMessage(state, arg: { upload: Upload; message: string }) {
      const { upload, message } = arg;
      upload.errorMessage = message;
      upload.status = UploadStatus.Error;
    },
    setUploadStatus(state, arg: { upload: Upload; status: UploadStatus }) {
      const { upload, status } = arg;
      upload.status = status;
    },
    updateUploadProgress(state, arg: { upload: Upload; progress: number }) {
      const { upload, progress } = arg;
      upload.progress = progress;
    },
    setUploadCancelAction(
      state,
      args: { upload: Upload; cancel?: () => void },
    ) {
      const { upload, cancel } = args;
      upload.cancel = cancel;
    },
    setUploadId(state, args: { upload: Upload; id: string }) {
      const { upload, id } = args;
      upload.id = id;
    },
    clearUploads(state) {
      state.uploads = [];
    },
  },
  actions: {
    async uploadFile(
      { commit }: ActionContext<DataState, RootState>,
      args: { upload: Upload; maxFileSize: number; url: string },
    ) {
      const { upload, maxFileSize, url } = args;
      const { file } = upload;
      if (!file) {
        commit('setUploadErrorMessage', {
          upload,
          message: `Not uploaded - file not found`,
        });
        return Promise.resolve();
      }
      if ((upload.fileSize || 0) > maxFileSize) {
        commit('setUploadErrorMessage', {
          upload,
          message: `Not uploaded - file exceeds maximum size of ${formatBytes(
            maxFileSize,
          )} per file`,
        });
        return Promise.resolve();
      }
      if (upload.fileSize === 0) {
        commit('setUploadErrorMessage', {
          upload,
          message: 'Not uploaded - file is empty',
        });
        return Promise.resolve();
      }
      if (
        upload.extension.toLowerCase() !== upload.type.extension.toLowerCase()
      ) {
        commit('setUploadErrorMessage', {
          upload,
          message: `Not uploaded - must have .${upload.type.extension} extension`,
        });
        return Promise.resolve();
      }
      commit('setUploadStatus', { upload, status: UploadStatus.Preparing });
      const progressHandler = (progress: number) =>
        commit('updateUploadProgress', { upload, progress });

      try {
        const client = await dataHubClient();
        const res = await client.uploadFileToRedirect(
          url,
          progressHandler,
          file,
          { originalFileName: upload.fileName },
        );
        if (upload.status === UploadStatus.Cancelled) {
          return Promise.resolve();
        }

        commit('setUploadCancelAction', { upload, cancel: res.cancel });
        commit('setUploadStatus', {
          upload,
          status: UploadStatus.Uploading,
        });
        return res.promise.then((uploadResponse) => {
          if (!isApiError(uploadResponse)) {
            commit('setUploadId', { upload, id: getData(uploadResponse) });
            commit('setUploadStatus', {
              upload,
              status: UploadStatus.Processing,
            });
            commit('setUploadCancelAction', { upload, cancel: undefined });
          } else if (upload.status !== UploadStatus.Cancelled) {
            commit('setUploadErrorMessage', {
              upload,
              message: uploadResponse.message,
            });
          }
          return uploadResponse;
        });
      } catch (e) {
        if (e instanceof Error) {
          commit('setUploadErrorMessage', { upload, message: e.message });
        }
        throw e;
      }
    },
    uploadFiles(
      { state, dispatch }: ActionContext<DataState, RootState>,
      args: { maxFileSize: number; url: string },
    ) {
      state.uploads
        .filter((upload) => upload.status === UploadStatus.Pending)
        .forEach((upload) => dispatch('uploadFile', { upload, ...args }));
    },
  },
};

export default datamodule;
