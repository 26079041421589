
import Vue from 'vue';
import Papa from 'papaparse';
import {
  ColumnDataType,
  Row,
  TableMetadata,
  TableViewModel,
} from '@/models/table.model';
import DataHubTable from '@/components/table/DataHubTable.vue';
import { buildTableViewModel } from '@/helpers/table';
import Button from '@/components/ripple/Button.vue';
import WidgetLoader from '@/components/WidgetLoader.vue';
import FlexCell from '@/components/table/FlexCell.vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { FileDownload } from '@/api/data';

type Data = {
  results?: Papa.ParseResult<string[]>;
  loading: boolean;
  file?: FileDownload;
  error: boolean;
};
type Computed = {
  tableViewModel?: TableViewModel;
};
type Methods = { onDownload: () => void };
type Props = { reportUrl: string };

Vue.component('flex-cell', FlexCell);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Preview',
  components: { Button, DataHubTable, WidgetLoader, RplIcon },
  props: { reportUrl: String },
  data() {
    return {
      results: undefined,
      loading: true,
      file: undefined,
      error: false,
    };
  },
  computed: {
    tableViewModel() {
      if (this.results) {
        const [header, ...data] = this.results.data;
        const tableMetadata: TableMetadata = {
          label: '',
          pageSize: 10,
          hideSpin: true,
          columns: header.map((h: string) => ({
            label: h,
            key: h,
            dataType: ColumnDataType.Component,
            filterable: true,
            sortable: true,
            searchable: true,
            actions: [],
          })),
        };
        const tableData: Row[] = data.map((rowData: string[]) => ({
          id: rowData[0],
          columns: rowData.map((d, i) => ({
            key: header[i],
            value: d,
            component: 'flex-cell',
          })),
        }));
        return buildTableViewModel(tableMetadata, tableData);
      }
      return undefined;
    },
  },
  methods: {
    onDownload() {
      if (this.file) {
        const blob = new Blob([this.file.buffer]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.file.fileName ? this.file.fileName : 'report';
        link.click();
      }
    },
  },
  async created() {
    try {
      const fileDownload = await this.$store.dispatch(
        'secureDownload',
        this.reportUrl,
      );
      this.file = fileDownload;
      Papa.parse<string[]>(new Blob([fileDownload.buffer]), {
        download: true,
        skipEmptyLines: true,
        complete: (results: Papa.ParseResult<string[]>) => {
          this.results = results;
        },
      });
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
});
