import { ActionContext, Module } from 'vuex';
import { cloneDeep } from 'lodash';

import { CommentResponse } from '@/components/widgets/comms/types';
import { CommentState } from './types';
import { RootState } from '../types';

const comment: Module<CommentState, RootState> = {
  state: () => ({
    comment: null,
  }),

  getters: {
    commentData(state): CommentResponse | null {
      return state.comment;
    },
  },
  mutations: {
    handleEditComment(state: CommentState, emailData: CommentResponse): void {
      const commentObject = cloneDeep(emailData);

      commentObject.update = true;
      state.comment = commentObject;
    },
    clearData(state: CommentState): void {
      state.comment = null;
    },
  },
  actions: {
    clearCommentData({ commit }: ActionContext<CommentState, RootState>): void {
      commit('clearData');
    },
    addCommentData(
      { commit }: ActionContext<CommentState, RootState>,
      payload: CommentResponse,
    ): void {
      if (payload) {
        commit('handleEditComment', payload);
      } else {
        commit('clearData');
      }
    },
  },
};

export default comment;
