import {
  WidgetType,
  WidgetTypeAndProps,
  WidgetProps,
} from '@/components/widgets/types';
import { WidgetProperty } from '@/models/dashboard.model';
import { Configuration } from '@/config/types';
import _template from 'lodash/template';
import _isString from 'lodash/isString';

export const findWidgetPropertyValue = <T extends WidgetType>(
  properties: WidgetProps<T>,
  code: WidgetTypeAndProps[T][number],
): string | undefined =>
  properties?.find((p) => p.definitionCode === code)?.value;

export function getWidgetPropertyValue<T extends WidgetType>(
  properties: WidgetProps<T>,
  code: WidgetTypeAndProps[T][number],
) {
  const widgetProperty = properties.find(
    (property) => property.definitionCode === code,
  );
  if (widgetProperty) {
    return widgetProperty.value;
  }
  throw new Error(`Missing widget property ${code}`);
}

export function getWidgetPropertyValueWith<T extends WidgetType, O>(
  properties: WidgetProps<T>,
  code: WidgetTypeAndProps[T][number],
  fallback: O,
  transform: (value: string) => O,
): O {
  const widgetValue = properties.find(
    (property) => property.definitionCode === code,
  )?.value;
  if (_isString(widgetValue)) {
    try {
      return transform(widgetValue);
    } catch (e) {
      console.warn(e);
      return fallback;
    }
  }
  return fallback;
}

export function templateConfigProperties(
  properties: WidgetProperty[],
  config: Configuration,
) {
  const result = properties.map((p) => {
    if (_isString(p.value)) {
      // Apply templating only if the value is a string
      // replace instances of <%= config.<anything> %> with values from config, note: interpolate and evaluate regexps must feature capture groups for the template function to work correctly
      return {
        definitionCode: p.definitionCode,
        value: _template(p.value, {
          interpolate: /<%=([\s]*config\..+?)%>/g,
          evaluate: /($^)/,
        })({ config }),
      };
    }
    // If the value is not a string, return it unchanged
    return p;
  });
  return result;
}
