
import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { RowViewModel } from '@/models/table.model';
import getConfig from '@/config';
import _template from 'lodash/template';
import { AttachmentUrl } from '@/api/data';

type Data = { downloading: boolean };
type Computed = unknown;
interface Methods {
  onClick: (event: Event) => void;
}
type Props = { rowViewModel: RowViewModel };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'OpenAttachmentAction',
  components: { RplIcon },
  data() {
    return { downloading: false };
  },
  props: { rowViewModel: Object },
  methods: {
    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      this.downloading = true;
      const config = await getConfig();
      const url = _template(this.rowViewModel.row.config.downloadURL)({
        query: this.$route.query,
        config,
        row: this.rowViewModel.row,
      });
      this.$store
        .dispatch('getDownloadUrl', url)
        .then((attachmentUrl: AttachmentUrl) => {
          window.open(attachmentUrl.url);
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
});
