export interface FileType {
  fileType: string;
  extension: string;
  displayName: string;
}

export enum UploadStatus {
  Pending = 'Pending',
  Preparing = 'Preparing',
  Uploading = 'Uploading',
  Processing = 'Processing',
  Validating = 'Validating',
  FailedValidation = 'Failed Validation',
  Complete = 'Complete',
  Infected = 'Infected',
  Error = 'Error',
  Cancelled = 'Cancelled',
}

export interface Upload {
  id: string;
  fileName: string;
  extension: string;
  status: UploadStatus;
  fileSize?: number;
  progress?: number;
  file?: File;
  errorMessage?: string;
  cancel?: () => void;
  type: FileType;
}

export interface UploadStatusDto {
  status: UploadStatus;
  errorMessage?: string;
}

export interface UploadDto {
  fileType: string;
  fileId: string;
  status: UploadStatus;
  uploadedBy: string;
  uploadedAt: string;
  originalFileName: string;
}
