import { RouteConfig } from 'vue-router';
import { pathFragments } from '@/router/internal/paths';
import ContactGroups from '@/components/pages/ContactGroups.vue';
import ContactGroup from '@/components/pages/ContactGroup.vue';
import AddGroupMember from '@/components/pages/AddGroupMember.vue';
import ChangePassword from '@/components/pages/ChangePassword.vue';
import MFA from '@/components/pages/MFA.vue';
import GroupDetailsForm from '@/components/pages/GroupDetailsForm.vue';
import Profile from '@/components/pages/Profile.vue';
import { fetchResource } from '@/components/widgets/comms/api';
import Login from '../components/pages/Login.vue';
import Logout from '../components/pages/Logout.vue';
import LoggedOut from '../components/pages/LoggedOut.vue';
import NotFound from '../components/pages/NotFound.vue';
import Dashboard from '../components/pages/Dashboard.vue';
import Page from '../components/pages/PageLayoutController.vue';
import { authenticatedRoutes } from './authenticatedRoutes';

export const Routes: RouteConfig[] = [
  {
    path: `/${pathFragments.login()}`,
    component: Login,
    name: 'login',
    meta: { breadcrumb: [{ text: 'Login' }] },
    props: true,
  },
  {
    path: `/:code/${pathFragments.logout()}`,
    component: Logout,
    meta: { breadcrumb: [{ text: 'Logout' }] },
    props: true,
  },
  {
    path: `/${pathFragments.logout()}`,
    component: Logout,
    meta: { breadcrumb: [{ text: 'Logout' }] },
    props: true,
  },
  {
    path: `/${pathFragments.loggedOut()}`,
    name: 'loggedOut',
    component: LoggedOut,
    meta: { breadcrumb: [{ text: 'Logout' }] },
    props: true,
  },
  {
    path: `/${pathFragments.public(':code')}`,
    name: 'public-dashboard',
    component: Dashboard,
    props: true,
    meta: {
      pagePathName: 'public-page',
    },
    children: [
      {
        path: pathFragments.page(':pageCode'),
        name: 'public-page',
        component: Page,
        props: true,
        meta: {
          pagePathName: 'public-page',
        },
      },
    ],
  },
  ...authenticatedRoutes(
    {
      path: '',
      name: 'root',
      component: NotFound,
    },
    {
      path: '/request-redirect/:resourceId',
      beforeEnter: async (to, from, next) => {
        if (to.params.resourceId !== '') {
          const resource = await fetchResource(to.params.resourceId);
          if (resource) {
            let pageCode = 'request';
            const tenant =
              resource.tenant.toLowerCase() === 'concierge'
                ? 'spc'
                : resource.tenant.toLowerCase();
            if (tenant === 'ppa') {
              switch (resource.type) {
                case 'Case management':
                  pageCode = 'case-management-request';
                  break;
                case 'Enquiry':
                  pageCode = 'enquiry';
                  break;
                default:
                  break;
              }
            }
            return next({
              name: 'page',
              query: { id: to.params.resourceId },
              params: { pageCode, code: tenant },
            });
          }
        }
        return next('');
      },
    },
    {
      path: `/${pathFragments.dashboard(':code')}`,
      name: 'dashboard',
      component: Dashboard,
      props: true,
      children: [
        {
          path: pathFragments.page(':pageCode'),
          name: 'page',
          component: Page,
          props: true,
        },
        {
          path: pathFragments.profile(),
          name: 'profile',
          component: Profile,
          props: true,
          meta: { breadcrumb: [{ text: 'User profile' }] },
        },
        {
          path: `${pathFragments.groups()}`,
          name: 'groups',
          component: ContactGroups,
          props: true,
          meta: { breadcrumb: [{ text: 'Manage groups' }] },
          children: [
            {
              path: pathFragments.group(':id'),
              name: 'group',
              component: ContactGroup,
              props: true,
            },
          ],
        },
        {
          path: `${pathFragments.addGroupMember(':groupId')}`,
          name: 'addGroupMember',
          component: AddGroupMember,
          props: true,
          meta: {
            breadcrumb: [
              { text: 'Manage groups', url: '../groups' },
              { text: 'Add group member' },
            ],
          },
        },
        {
          path: `${pathFragments.editGroupDetails(':groupId')}`,
          name: 'editGroupDetails',
          component: GroupDetailsForm,
          props: true,
          meta: {
            breadcrumb: [
              { text: 'Manage groups', url: '../groups' },
              { text: 'Edit group details' },
            ],
          },
        },
        {
          path: `${pathFragments.changePassword()}`,
          name: 'changePassword',
          component: ChangePassword,
          props: true,
          meta: { breadcrumb: [{ text: 'Change password' }] },
        },
        {
          path: pathFragments.mfa(),
          component: MFA,
          meta: { breadcrumb: [{ text: 'Manage two-step verification' }] },
          props: true,
        },
      ],
    },
  ),
  {
    path: '*',
    props: true,
    component: NotFound,
  },
];
