import { ActionContext, Module } from 'vuex';
import { getData, getErrorMessage, isApiError } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';
import { ChartState } from './types';
import { RootState } from '../types';

const chartmodule: Module<ChartState, RootState> = {
  state: () => ({}),
  actions: {
    loadFilterCount(
      { commit, rootState, rootGetters }: ActionContext<ChartState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadFilterCount',
        (client) => client.loadFilterCount(url),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
  },
};

export default chartmodule;
