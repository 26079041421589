import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import configureAuthService from '@/api/auth';
import { UserTokenService } from './types';

class RestClient {
  private cachedUserTokenService?: UserTokenService; // cached service, use this.userTokenService()

  private authProviderFn: () => Promise<UserTokenService>;

  constructor(authProviderFn = configureAuthService) {
    this.authProviderFn = authProviderFn;
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    console.debug(`${config.method} url: ${config.url}`);
    return axios.request<T>({
      ...config,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...config.headers,
        Authorization: `Bearer ${await this.token()}`,
      },
    });
  }

  async get<T>(
    url: string,
    params: Record<string, unknown> | undefined = undefined,
  ): Promise<AxiosResponse<T>> {
    console.debug(`getting url: ${url}`);
    return axios.get<T>(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.token()}`,
      },
      params,
    });
  }

  async post<T, B>(url: string, data: B): Promise<AxiosResponse<T>> {
    console.debug(`posting url: ${url}`);
    return axios.post<T>(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.token()}`,
      },
    });
  }

  async put<T, B>(url: string, data: B): Promise<AxiosResponse<T>> {
    console.debug(`putting url: ${url}`);
    return axios.put<T>(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.token()}`,
      },
    });
  }

  async patch<T, B>(url: string, data: B): Promise<AxiosResponse<T>> {
    console.debug(`patching url: ${url}`);
    return axios.patch<T>(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.token()}`,
      },
    });
  }

  async delete<T>(url: string): Promise<AxiosResponse<T>> {
    console.debug(`deleting url: ${url}`);
    return axios.delete<T>(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.token()}`,
      },
    });
  }

  async token() {
    return (await this.userTokenService()).getAccessToken();
  }

  private async userTokenService(): Promise<UserTokenService> {
    if (!this.cachedUserTokenService) {
      this.cachedUserTokenService = await this.authProviderFn();
    }
    return this.cachedUserTokenService;
  }
}

const client = new RestClient();

export default client;
