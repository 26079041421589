import { RouteConfig } from 'vue-router';

export const authenticatedRoutes = (...routes: RouteConfig[]): RouteConfig[] =>
  routes.map((route) => ({
    ...route,
    meta: { ...(route.meta ? route.meta : {}), requireAuth: true },
    ...(route.children
      ? { children: authenticatedRoutes(...route.children) }
      : {}),
  }));
