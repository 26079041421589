<template>
  <div>
    <h4>
      The page you've asked for does not exist, please check the url and try
      again or <router-link :to="{ name: 'root' }">return home</router-link>
    </h4>
    <h4>
      If the problem persists, please contact
      <a href="mailto:planning.support@transport.vic.gov.au"
        >planning.support@transport.vic.gov.au</a
      >.
    </h4>
  </div>
</template>

<script>
import { paths } from '@/router/paths';

export default {
  name: 'NotFoundPage',
  data() {
    return {
      location: '',
    };
  },
  computed: {
    home() {
      return paths.base();
    },
  },
  created() {
    this.$store.commit('setHeaderText', 'Page not found');
    this.$store.commit('setBreadcrumbs', [{ text: 'Page not found' }]);
  },
};
</script>
