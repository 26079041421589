<template>
  <div class="button" v-bind:class="{ inverse }" @click="click">
    <RplButton
      :class="{ delete: theme === 'delete' }"
      v-bind="$props"
      ref="button"
    >
      <slot></slot>
    </RplButton>
  </div>
</template>

<script>
import RplButton from '@dpc-sdp/ripple-button';

export default {
  name: 'Button',
  components: {
    RplButton,
  },
  methods: {
    click(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
    focus() {
      this.$refs.button.$el.focus();
    },
    hasFocus() {
      return document.activeElement === this.$refs.button.$el;
    },
  },
  props: { theme: String, disabled: Boolean, inverse: Boolean },
};
</script>
<style lang="scss" scoped>
.button {
  display: inline-block;
  button:disabled {
    background-color: $neutral;
  }
}
.inverse {
  button:not(:disabled) {
    background-color: white;
    border-width: 1px;
    &.rpl-button--primary {
      color: $rpl-button-primary-background-color;
      border-color: $rpl-button-primary-background-color;
      &:hover {
        color: $rpl-button-primary-hover-background-color;
        border-color: $rpl-button-primary-hover-background-color;
      }
    }
    &.rpl-button--secondary {
      color: $secondary;
      border-color: $secondary;
      &:hover {
        color: $rpl-button-secondary-hover-background-color;
        border-color: $rpl-button-secondary-hover-background-color;
      }
    }
  }
}

.delete:not(:disabled) {
  background-color: $semantic-cancel;
}
</style>
