
import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';

type Data = {};
type Computed = {
  loading: boolean;
  error: boolean;
};
type Methods = {};
type Props = { loading: boolean; error: boolean; label: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'WidgetLoader',
  components: { RplIcon },
  props: {
    loading: Boolean,
    error: Boolean,
    label: String,
  },
});
