import { render, staticRenderFns } from "./MakeRelatedAction.vue?vue&type=template&id=1b8b6b0d&scoped=true&"
import script from "./MakeRelatedAction.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MakeRelatedAction.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MakeRelatedAction.vue?vue&type=style&index=0&id=1b8b6b0d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8b6b0d",
  null
  
)

export default component.exports