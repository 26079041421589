
import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { RowViewModel } from '@/models/table.model';
import Confirm from '@/components/Confirm.vue';
import getConfig from '@/config';
import _template from 'lodash/template';
import { useEmitWidgetEvent } from '@/components/composables/events/useWidgetEvents';

type Data = {
  loading: boolean;
  showConfirmDelete: boolean;
  errorMessage: string;
};
type Computed = unknown;
interface Methods {
  onClick: (event: Event) => void;
  resolveConfirmModal: (doDelete: boolean) => void;
}
type Props = {
  rowViewModel: RowViewModel;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DeleteAttachmentAction',
  components: { RplIcon, Confirm },
  setup() {
    const deletedEvent = useEmitWidgetEvent('attachment-deleted');
    return { deletedEvent };
  },
  data() {
    return {
      loading: false,
      showConfirmDelete: false,
      errorMessage: '',
    };
  },
  props: { rowViewModel: Object },
  methods: {
    resolveConfirmModal(doDelete: boolean) {
      if (doDelete) {
        getConfig().then((config) => {
          const url = _template(this.rowViewModel.row.config.deleteURL)({
            query: this.$route.query,
            config,
            row: this.rowViewModel.row,
          });
          this.$store
            .dispatch('deleteResourceAttachment', url)
            .then(() => {
              this.deletedEvent(this.rowViewModel.row.id);
              if (this.rowViewModel.callBackFunction !== undefined) {
                this.rowViewModel.callBackFunction({
                  action: 'refresh',
                  rowViewModel: this.rowViewModel,
                });
              }
              this.showConfirmDelete = false;
            })
            .catch((err) => {
              console.log(err);
              this.errorMessage = err.message;
            })
            .finally(() => {
              this.loading = false;
            });
        });
      } else {
        this.loading = false;
        this.showConfirmDelete = false;
        this.errorMessage = '';
      }
    },

    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      this.loading = true;
      this.errorMessage = '';
      this.showConfirmDelete = true;
    },
  },
});
