import { ActionContext, Module } from 'vuex';
import {
  Batch,
  BatchStatus,
  BatchType,
  BatchUploadDetails,
  BatchUploadStatus,
  Upload,
  UploadStatus,
  UploadUpdate,
} from '@/models/upload.model';
import { MAX_FILE_SIZE_BYTES } from '@/config/constants';
import { recordApiCall } from '@/store/modules/apiCalls';
import { ResponsibleAuthority } from '@/models/responsible-authority.model';
import {
  ApiErrorType,
  getData,
  hasData,
  isApiError,
  LoadedData,
} from '@/api/data';
import { dataHubClient } from '@/api';
import { RootState } from '../types';
import { UploadState } from './types';

const uploadmodule: Module<UploadState, RootState> = {
  namespaced: true,
  state: () => ({
    uploads: [],
    uploading: false,
    batch: undefined,
    errorMessage: undefined,
  }),
  mutations: {
    addUploads(state, args: { files: FileList; allowedTypes: string[] }) {
      Array.from(args.files).forEach((file) => {
        if (
          !args.allowedTypes.some((type) =>
            file.name.toLowerCase().endsWith(type),
          )
        ) {
          state.uploads.push({
            file,
            status: UploadStatus.InvalidType,
            message: 'Invalid file type, will not upload',
          });
        } else if (MAX_FILE_SIZE_BYTES < file.size) {
          state.uploads.push({
            file,
            status: UploadStatus.Error,
            message: 'File exceeds maximum size, will not upload',
          });
        } else {
          state.uploads.push({
            file,
            status: UploadStatus.Queued,
            message: 'Queued',
            progress: 0,
            details: {},
          });
        }
      });
    },
    removeUpload(state, upload: Upload) {
      state.uploads = state.uploads.filter((u) => u !== upload);
    },
    clearUploads(state) {
      state.uploads = [];
      state.uploading = false;
      state.batch = undefined;
      state.errorMessage = undefined;
    },
    updateUpload(state, uploadUpdate: UploadUpdate) {
      const {
        fileId,
        upload,
        status,
        message,
        progress,
        cancelAction,
        details,
      } = uploadUpdate;
      upload.fileId = fileId;
      upload.status = status;
      upload.message = message;
      upload.progress = progress;
      upload.cancelAction = cancelAction;
      upload.details = details;
    },
    updateUploadDetails(
      state,
      args: { upload: Upload; details: BatchUploadDetails },
    ) {
      const { upload, details } = args;
      upload.details = { ...upload.details, ...details };
    },
    setUploading(state, uploading: boolean) {
      state.uploading = uploading;
    },
    setBatch(state, batch: Batch) {
      state.batch = batch;
    },
    setBatchStatus(state, status: BatchStatus) {
      if (state.batch) {
        state.batch.status = status;
      }
    },
    setErrorMessage(state, message: string) {
      state.errorMessage = message;
    },
  },
  getters: {
    canUpload(state): boolean {
      return (
        state.uploads.some((upload) => upload.status === UploadStatus.Queued) &&
        !state.uploading
      );
    },
    batchProgress(state): string {
      if (!state.batch) {
        return 'opening batch';
      }
      const total = state.uploads.filter(
        (upload: Upload) => upload.status !== UploadStatus.Error,
      ).length;
      const complete = state.uploads.filter(
        (upload: Upload) =>
          upload.status === UploadStatus.Processing ||
          upload.status === UploadStatus.Rejected ||
          upload.status === UploadStatus.Complete,
      ).length;
      return `${complete} of ${total} files uploaded`;
    },
    batchProcessing(state): boolean {
      return (
        state.batch !== undefined &&
        state.uploads.some(
          (upload) => upload.status === UploadStatus.Processing,
        )
      );
    },
    uploading(state): boolean {
      return state.uploads.some(
        (upload) => upload.status === UploadStatus.Uploading,
      );
    },
  },
  actions: {
    uploadFile(
      { commit }: ActionContext<UploadState, RootState>,
      args: {
        ra: ResponsibleAuthority;
        batch: Batch;
        type: BatchType;
        upload: Upload;
        endpoint: string;
      },
    ) {
      const { batch, upload } = args;
      const progressHandler = (progress: number) => {
        const transferComplete = progress >= upload.file.size;
        commit('updateUpload', {
          upload,
          progress,
          message: transferComplete ? 'Processing' : upload.message,
          cancelAction: upload.cancelAction,
          status: transferComplete ? UploadStatus.Processing : upload.status,
        });
      };

      return dataHubClient()
        .then((client) =>
          client
            .uploadFile(batch, upload.file, args.endpoint, progressHandler)
            .then((cancellable) => {
              commit('updateUpload', {
                upload,
                progress: upload.progress,
                message: 'Uploading',
                cancelAction: cancellable.cancel,
                status: UploadStatus.Uploading,
              });
              return cancellable.promise;
            }),
        )
        .then((res) => {
          if (isApiError(res)) {
            if (res.type === ApiErrorType.BadGateway) {
              commit('updateUpload', {
                upload,
                message: 'Processing',
                status: UploadStatus.Processing,
              });
            } else {
              commit('updateUpload', {
                upload,
                message: `Not uploaded - ${res.message}`,
                status: UploadStatus.Error,
              });
            }
          } else {
            const uploadResponse = getData(res);
            if (uploadResponse.status === BatchUploadStatus.Rejected) {
              commit('updateUpload', {
                upload,
                fileId: uploadResponse.fileId,
                message: 'Rejected',
                status: UploadStatus.Rejected,
              });
            } else {
              commit('updateUpload', {
                upload,
                fileId: uploadResponse.fileId,
                message: 'Processing',
                status: UploadStatus.Processing,
              });
            }
          }
        });
    },
    uploadFiles(
      { state, dispatch }: ActionContext<UploadState, RootState>,
      args: {
        ra: ResponsibleAuthority;
        batch: Batch;
        type: BatchType;
        endpoint: string;
      },
    ) {
      const upload = state.uploads.find(
        (u) => u.status === UploadStatus.Queued,
      );
      if (upload) {
        return dispatch('uploadFile', { ...args, upload }).then(() =>
          dispatch('uploadFiles', args),
        );
      }
      return dispatch('closeBatchUpload', {
        batch: args.batch,
        endpoint: args.endpoint,
        type: args.type,
      });
    },
    startUploadFiles(
      {
        commit,
        state,
        rootState,
        rootGetters,
        dispatch,
      }: ActionContext<UploadState, RootState>,
      args: { ra: ResponsibleAuthority; type: BatchType; endpoint: string },
    ) {
      commit('setUploading', true);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'startBatchUpload',
        (client) => client.startBatchUpload(args.ra, args.type, args.endpoint),
      ).then((response) => {
        if (isApiError(response)) {
          commit('setUploading', false);
          commit('setErrorMessage', response.message);
        }

        if (hasData(response)) {
          const batch = getData(response);
          commit('setBatch', { ...batch, status: BatchStatus.Open });
          return dispatch('uploadFiles', {
            ra: args.ra,
            batch: state.batch,
            type: args.type,
            endpoint: args.endpoint,
          }).then((uploadResponse) => {
            if (isApiError(uploadResponse)) {
              commit('setErrorMessage', uploadResponse.message);
              commit('setBatchStatus', BatchStatus.Error);
            }
            return uploadResponse;
          });
        }
        return response;
      });
    },
    closeBatchUpload(
      { commit, dispatch }: ActionContext<UploadState, RootState>,
      args: { batch: Batch; endpoint: string; type: BatchType },
    ) {
      commit('setUploading', false);
      commit('setBatchStatus', BatchStatus.Closed);
      return dataHubClient()
        .then((client) => client.closeBatchUpload(args.batch, args.endpoint))
        .then((response) => {
          dispatch('pollBatchStatus', {
            endpoint: args.endpoint,
            type: args.type,
          });
          // ignore gateway timeout errors on close batch
          if (isApiError(response) && response.type === 'BadGateway') {
            return LoadedData(undefined);
          }
          return response;
        });
    },
    pollBatchStatus(
      {
        commit,
        dispatch,
        state,
        rootState,
        rootGetters,
      }: ActionContext<UploadState, RootState>,
      args: { endpoint: string; type: BatchType },
    ) {
      const { batch } = state;
      if (batch) {
        return recordApiCall(
          { rootState, rootGetters, commit },
          'getBatchStatus',
          (client) => client.getBatchStatus(batch.batchCode, args.endpoint),
        ).then((response) => {
          if (hasData(response)) {
            const responseBatch = getData(response);
            if (responseBatch.status !== BatchStatus.Complete) {
              setTimeout(() => dispatch('pollBatchStatus', args), 5000);
            } else {
              commit('setBatchStatus', BatchStatus.Complete);
              commit('setBatch', responseBatch);
              if (responseBatch.files) {
                responseBatch.files.forEach((details) => {
                  const upload = state.uploads.find(
                    (u) => u.fileId === details.fileId,
                  );
                  if (upload) {
                    commit('updateUpload', {
                      upload,
                      status:
                        details.status === BatchUploadStatus.Accepted
                          ? UploadStatus.Complete
                          : UploadStatus.Rejected,
                    });
                    commit('updateUploadDetails', {
                      upload,
                      details: { ...details, type: args.type },
                    });
                  }
                });
              }
            }
          }
          return response;
        });
      }
      return Promise.resolve();
    },
  },
};

export default uploadmodule;
