
import Vue from 'vue';
import RplBreadcrumbs from '@dpc-sdp/ripple-breadcrumbs';
import { Breadcrumb } from '@/models/site.model';

type Data = {};
interface Computed {
  crumbs: Breadcrumb[];
}
type Methods = {
  onClick: (event: MouseEvent) => void;
};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'BreadCrumb',
  components: {
    RplBreadcrumbs,
  },
  computed: {
    crumbs() {
      return this.$store.getters.crumbs;
    },
  },
  methods: {
    onClick(event) {
      const elem = (event.target as HTMLElement).closest(
        'a',
      ) as HTMLAnchorElement;
      if (elem && elem.pathname.startsWith('/')) {
        event.stopPropagation();
        event.preventDefault();
        this.$router.push(elem.pathname);
      }
    },
  },
  updated() {
    document.body.focus();
  },
});
