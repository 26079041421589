
import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { RowViewModel } from '@/models/table.model';
import getConfig from '@/config';
import _template from 'lodash/template';
import { useEmitWidgetEvent } from '@/components/composables/events/useWidgetEvents';

type Data = {
  loading: boolean;
};
type Computed = unknown;
interface Methods {
  onClick: (event: Event) => void;
}
type Props = {
  rowViewModel: RowViewModel;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AddRoleToUserAction',
  components: { RplIcon },
  setup() {
    const roleAddedEvent = useEmitWidgetEvent('role-added');
    return { roleAddedEvent };
  },
  data() {
    return {
      loading: false,
    };
  },
  props: { rowViewModel: Object },
  methods: {
    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      this.loading = true;
      getConfig().then((config) => {
        const url = _template(this.rowViewModel.row.config.addRoleURL)({
          query: this.$route.query,
          config,
          row: this.rowViewModel.row,
        });

        const body = { roleId: this.rowViewModel.row.id };
        this.$store
          .dispatch('addRoleToUser', { url, body })
          .then(() => {
            this.roleAddedEvent(body.roleId);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
});
