import moment from 'moment';

const FORMATS = { date: 'DD MMM YYYY', datetime: 'DD MMM YYYY h:mm A' };

export const formatDate = (value: string | number) => {
  if (value) {
    const dateObj = moment(
      value,
      typeof value === 'string' ? moment.ISO_8601 : undefined,
    );
    if (dateObj.isValid()) {
      return dateObj.format(FORMATS.date);
    }
  }
  return '';
};

export const formatDateTime = (value: string | number) => {
  if (value) {
    const dateObj = moment(
      value,
      typeof value === 'string' ? moment.ISO_8601 : undefined,
    );
    if (dateObj.isValid()) {
      return dateObj.format(FORMATS.datetime);
    }
  }
  return '';
};

export const formatDateOrDateTime = (value: string) => {
  if (value && /\d{2}:\d{2}/.test(value)) {
    return formatDateTime(value);
  }
  return formatDate(value);
};

export const isValid = (value: string) => {
  if (value) {
    return moment(value, moment.ISO_8601).isValid();
  }
  return false;
};

export const isSame = (date: string | number, testDate: string | number) =>
  moment(date).isSame(testDate, 'day');

export const isBefore = (date: string | number, testDate: string | number) =>
  moment(date).isBefore(testDate, 'day');

export const isAfter = (date: string | number, testDate: string | number) =>
  moment(date).isAfter(testDate, 'day');

export const isSameOrBefore = (
  date: string | number,
  testDate: string | number,
) => moment(date).isSameOrBefore(testDate, 'day');

export const isSameOrAfter = (
  date: string | number,
  testDate: string | number,
) => moment(date).isSameOrAfter(testDate, 'day');

export const isBetween = (
  date: string | number,
  startDate: string | number,
  endDate: string | number,
) => moment(date).isBetween(startDate, endDate, 'day', '[]');
