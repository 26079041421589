
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};

type Computed = { text: string };

type Methods = {};

type Props = {
  rowViewModel: RowViewModel;
  viewPath: (row: RowViewModel) => string;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'WordWrappingCell',
  props: {
    rowViewModel: Object,
    viewPath: Function,
    column: Object,
  },
  computed: {
    text() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
});
