
import { abstractField } from 'vue-form-generator';
import Vue from 'vue';

type Data = {
  show: boolean;
};
type Methods = { onShow: () => void };
type Computed = { type: string; showText: string };
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FieldPassword',
  components: {},
  mixins: [abstractField],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    type() {
      return this.show ? 'text' : 'password';
    },
    showText() {
      return this.show ? 'Hide password' : 'Show password';
    },
  },
  methods: {
    onShow() {
      this.show = !this.show;
    },
  },
});
