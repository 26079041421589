<template>
  <Button
    inverse
    theme="primary"
    type="action"
    @click.prevent.stop="schema.clickHandler"
  >
    {{ schema.buttonText }}
  </Button>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import Button from '../Button.vue';

export default {
  mixins: [abstractField],
  name: 'FormButton',
  components: {
    Button,
  },
  methods: {},
};
</script>
