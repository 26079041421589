
import Vue from 'vue';
import { Column, RowViewModel } from '@/models/table.model';
import IconButton from '@/components/ripple/form/IconButton.vue';
import Preview from '@/components/shared/Preview.vue';
import { columnValue } from '@/helpers/table';
import TextIconLink from '@/components/ripple/TextIconLink.vue';

type Data = { showPreview: boolean };

type Computed = { reportUrl: string };

interface Methods {
  onOpenPreview: () => void;
  onClosePreview: () => void;
}

type Props = { rowViewModel: RowViewModel; column: Column; label?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'PreviewAction',
  components: { IconButton, Preview, TextIconLink },
  props: { rowViewModel: Object, column: Object, label: String },
  data() {
    return { showPreview: false };
  },
  computed: {
    reportUrl() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
  methods: {
    onOpenPreview() {
      this.showPreview = true;
    },
    onClosePreview() {
      this.showPreview = false;
    },
  },
});
