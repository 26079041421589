export enum ApiStates {
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  NEVER_LOADED = 'NEVER_LOADED',
}

export enum ApiErrorType {
  RequestCancelled = 'RequestCancelled',
  NotFound = 'NotFound',
  Unexpected = 'Unexpected',
  ConnectorForbidden = 'ConnectorForbidden',
  BadGateway = 'BadGateway',
  Unauthorised = 'Unauthorised',
  Duplicate = 'Duplicate',
}

export type LoadedData<T> = { data: T; state: ApiStates.LOADED };
export type ApiLoading<T> = { state: ApiStates.LOADING; data?: T };
export type ApiError = {
  state: ApiStates.ERROR;
  message: string;
  type: keyof typeof ApiErrorType;
};

export type ApiNeverLoaded = { state: ApiStates.NEVER_LOADED };

/** This type describes a response that may or may not be loaded yet. Use it outside the API layer */
export type DataFromApi<T> =
  | LoadedData<T>
  | ApiLoading<T>
  | ApiError
  | ApiNeverLoaded;

/** This type describes a response from the API - either data or an error. Use it to return from the API layer */
export type ApiResponse<T> = LoadedData<T> | ApiError;

export type ApiPromise<T> = Promise<ApiResponse<T>>;

export const isApiError = <T>(object: DataFromApi<T>): object is ApiError =>
  object &&
  object.state === ApiStates.ERROR &&
  typeof object.message === 'string';

export const isLoading = <T>(object: DataFromApi<T>): object is ApiLoading<T> =>
  object &&
  (object.state === ApiStates.LOADING ||
    object.state === ApiStates.NEVER_LOADED);

export const hasData = <T>(object: DataFromApi<T>): object is LoadedData<T> =>
  object &&
  (object.state === ApiStates.LOADED || object.state === ApiStates.LOADING) &&
  object.data != null;

export const getErrorMessage = <T>(object: ApiError): string => object.message;

export const getData = <T>(payload: LoadedData<T>) => payload.data;

export const getDataOrThrow = <T>(payload: DataFromApi<T>) => {
  if (!hasData(payload)) {
    throw new Error(
      `Tried to get data from an API object, but was in the '${payload.state}' state`,
    );
  }
  return getData(payload);
};

export const ApiLoading = <T>(oldData: DataFromApi<T>): ApiLoading<T> =>
  hasData(oldData)
    ? {
        state: ApiStates.LOADING,
        data: getData(oldData),
      }
    : { state: ApiStates.LOADING };

export const LoadedData = <T>(data: T): LoadedData<T> => ({
  state: ApiStates.LOADED,
  data,
});

export const UnexpectedApiError = (message: string): ApiError => ({
  state: ApiStates.ERROR,
  message,
  type: ApiErrorType.Unexpected,
});

export const ApiNeverLoaded: ApiNeverLoaded = { state: ApiStates.NEVER_LOADED };

export type FileDownload = {
  buffer: ArrayBuffer;
  fileName?: string;
};

export type AttachmentUrl = {
  url: string;
};
