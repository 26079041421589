
import { abstractField } from 'vue-form-generator';
import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import IconButton from '@/components/ripple/form/IconButton.vue';

type Data = {
  show: boolean;
  values: string[];
};
type Methods = {
  onChange: (index: number, event: InputEvent) => void;
  onAdd: () => void;
  onDelete: (index: number) => void;
};
type Computed = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FieldStrings',
  components: { IconButton, RplTextLink },
  mixins: [abstractField],
  data() {
    return {
      show: false,
      values: [''],
    };
  },
  methods: {
    onChange(index, event) {
      this.values[index] = (event.target as HTMLInputElement).value;
      this.value = [...this.values];
    },
    onAdd() {
      this.values.push('');
      this.value = [...this.values];
    },
    onDelete(index) {
      if (this.values.length === 1) {
        this.values = [''];
      } else {
        this.values.splice(index, 1);
      }
      this.value = [...this.values];
    },
  },
  mounted() {
    if (this.value.length) {
      this.values = [...this.value];
    }

    const validators = Array.isArray(this.schema.validator)
      ? [...this.schema.validator]
      : [];
    validators.push((values: string[]) => {
      if (this.schema.min > values.filter((v) => v.trim() !== '').length) {
        return [`Must be at least ${this.schema.min}`];
      }
      return [];
    });
    this.schema.validator = validators;
  },
});
