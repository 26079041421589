
import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import Button from '@/components/ripple/Button.vue';
import { Group } from '@/models/group.model';
import {
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
} from '@/api/data';
import {
  ColumnDataType,
  // RowViewModel,
  Row,
  TableViewModel,
  TableMetadata,
  TableComponent,
} from '@/models/table.model';
import DataHubTable from '@/components/table/DataHubTable.vue';
import DeleteGroupMember from '@/components/table/actions/group/DeleteGroupMember.vue';
import { buildTableViewModel } from '@/helpers/table';

interface Data {
  tableComponents: TableComponent[];
}

type Methods = {
  onAddMember: () => void;
  onCloseMessage: () => void;
  onCloseError: () => void;
};
type Computed = {
  group: Group;
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  errorMessage: string;
  tableData: Row[];
  tableDefinition: TableMetadata;
  message?: string;
  error?: string;
  tableViewModel?: TableViewModel;
};
type Props = { id: string; code: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ContactGroup',
  components: { RplIcon, DataHubTable, Button, RplAlertBase },
  props: { id: String, code: String },
  data() {
    return {
      tableComponents: [
        { name: 'deleteGroupMember', component: DeleteGroupMember },
      ],
    };
  },
  computed: {
    isLoading() {
      return isLoading(this.$store.state.group.groupFromApi);
    },
    isError() {
      return isApiError(this.$store.state.group.groupFromApi);
    },
    group() {
      return getData(this.$store.state.group.groupFromApi);
    },
    isLoaded() {
      return hasData(this.$store.state.group.groupFromApi);
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.group.groupFromApi) ||
        'Error loading group'
      );
    },
    tableViewModel() {
      return this.tableData && this.tableDefinition
        ? buildTableViewModel(this.tableDefinition, this.tableData)
        : undefined;
    },
    tableData() {
      return [...this.group.contacts]
        .sort((c1, c2) => {
          if (c1.firstName === undefined && c2.firstName === undefined) {
            return 0;
          }
          if (c1.firstName === undefined) {
            return -1;
          }
          if (c2.firstName === undefined) {
            return 1;
          }
          return c1.firstName.localeCompare(c2.firstName);
        })
        .map((contact) => ({
          id: contact.id,
          columns: [
            {
              key: 'name',
              value: `${contact.firstName || ''} ${contact.lastName || ''}`,
            },
            { key: 'email', value: contact.email || '' },
            { key: 'delete-group-member', actions: ['delete-group-member'] },
          ],
        }));
    },
    tableDefinition() {
      return {
        label: `Members of ${this.group.name}`,
        columns: [
          {
            label: 'Name',
            key: 'name',
            dataType: ColumnDataType.Text,
            filterable: false,
            sortable: false,
            searchable: false,
            actions: [],
          },
          {
            label: 'Email',
            key: 'email',
            dataType: ColumnDataType.Text,
            filterable: false,
            sortable: false,
            searchable: false,
            actions: [],
          },
          {
            label: '',
            key: 'delete-group-member',
            dataType: ColumnDataType.Action,
            filterable: false,
            sortable: false,
            searchable: false,
            actions: [],
          },
        ],
        pageSize: 10,
        hideSpin: true,
      };
    },
    message() {
      return this.$store.state.group.message;
    },
    error() {
      return this.$store.state.group.error;
    },
  },
  methods: {
    onAddMember() {
      this.$router.push({
        name: 'addGroupMember',
        params: { groupId: this.group.id },
      });
    },
    onCloseMessage() {
      this.$store.commit('setMessage', undefined);
    },
    onCloseError() {
      this.$store.commit('setError', undefined);
    },
  },
  mounted() {
    if (!this.isLoaded || this.group.id !== this.id) {
      this.$store.dispatch('getGroup', {
        groupId: this.id,
        dashboardCode: this.code,
      });
    }
  },
  watch: {
    id() {
      this.$store.dispatch('getGroup', {
        groupId: this.id,
        dashboardCode: this.code,
      });
    },
  },
});
