
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import Vue from 'vue';
import Markdown from '@/components/markdown/Markdown.vue';
import { getData, hasData } from '@/api/data';

type Data = {};
type Props = {};
type Computed = { notice?: string };
type Methods = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Notice',
  components: { RplAlertBase, Markdown },
  computed: {
    notice() {
      const { dashboardFromApi } = this.$store.state.dashboard;
      return hasData(dashboardFromApi)
        ? getData(dashboardFromApi).notice
        : undefined;
    },
  },
});
