import { ActionContext, Module } from 'vuex';
import { getData, getErrorMessage, isApiError } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';
import { TableState } from './types';
import { RootState } from '../types';

const tablemodule: Module<TableState, RootState> = {
  state: () => ({}),
  actions: {
    loadTableData(
      { commit, rootState, rootGetters }: ActionContext<TableState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadTableData',
        (client) => client.loadTableData(url),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
    loadSimpleData(
      { commit, rootState, rootGetters }: ActionContext<TableState, RootState>,
      url: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadSimpleData',
        (client) => client.loadSimpleData(url),
      ).then((response) => {
        if (!isApiError(response)) {
          return getData(response);
        }
        throw new Error(getErrorMessage(response));
      });
    },
  },
};

export default tablemodule;
