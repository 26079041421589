import defaultGetConfig from '@/config';
import { ApiConfig } from '@/config/types';
import logger from '@/logger';
import { Dashboard } from '@/models/dashboard.model';
import {
  IdResponse,
  ListResourceAttachmentResponse,
  ListResourcePermissionDto,
  ListResourceResponse,
  PartyType,
  PermissionType,
  ResourceDto,
  ResourceResponse,
} from '@/models/resource.model';
import { ResponsibleAuthority } from '@/models/responsible-authority.model';
import {
  Batch,
  BatchType,
  BatchUploadDetails,
  UploadResponse,
} from '@/models/upload.model';
import { Row } from '@/models/table.model';
import { Group, GroupDetails } from '@/models/group.model';
import { User, UserProfile } from '@/models/user.model';
import { Tenant, ApiCallName, Cancellable, DataHubClient } from '@/api/types';
import { ReportClient } from '@/components/widgets/data-exchange/model/dataset.model';
import { FilterCount } from '@/models/controller.model';
import {
  DashboardDto,
  DashboardSummaryDto,
  ExportDashboard,
  PageDto,
  WidgetDefinitionDto,
  WidgetDto,
} from '@/models/admin.model';
import {
  Group as AppEngGroup,
  GroupSearchResponse,
} from '@/models/app-engine/group.model';
import {
  User as AppEngUser,
  UserSearchResponse,
} from '@/models/app-engine/user.model';
import { ApiKeyIndex } from '@/models/api-key.model';
import connector from './connector';
import AUTH_SERVICE_FACTORY from './auth';
import { ApiPromise, FileDownload, AttachmentUrl } from './data';

const clientInitialiser = (
  apiHostClient: ReturnType<typeof connector>,
  dataHubClient: ReturnType<typeof connector>,
  baseClient: ReturnType<typeof connector>,
  appEngClient: ReturnType<typeof connector>,
) => ({
  loadDashboard: async (dashboardCode: string): ApiPromise<Dashboard> => {
    logger.debug(`loadDashboard: dashboardCode: ${dashboardCode}`);
    return dataHubClient.get(`/dashboards/${dashboardCode}`);
  },
  loadPublicDashboard: async (dashboardCode: string): ApiPromise<Dashboard> => {
    logger.debug(`loadPublicDashboard: dashboardCode: ${dashboardCode}`);
    return dataHubClient.get(`/public/dashboards/${dashboardCode}`);
  },
  loadDashboardSummaries: async (): ApiPromise<DashboardSummaryDto[]> => {
    logger.debug(`loadDashboardSummaries`);
    return dataHubClient.get(`/dashboards`);
  },
  createDashboard: async (
    code: string,
    tenant: string,
    managed: boolean,
    publicDashboard: boolean,
    headerBgColour?: string,
    headerImgColour?: string,
  ): ApiPromise<void> => {
    logger.debug(`createDashboard: dashboardCode: ${code}, tenant: ${tenant}`);
    return dataHubClient.post('/dashboards', {
      code,
      tenant,
      managed,
      public: publicDashboard,
      defaultPageCode: 'tmp',
      headerBgColour,
      headerImgColour,
    });
  },
  updateDashboard: async (dashboard: DashboardDto): ApiPromise<void> => {
    logger.debug(`updateDashboard: dashboardCode: ${dashboard.code}`);
    return dataHubClient.patch(`/dashboards/${dashboard.code}`, dashboard);
  },
  deleteDashboard: async (code: string): ApiPromise<void> => {
    logger.debug(`deleteDashboard: dashboardCode: ${code}`);
    return dataHubClient.delete(`/dashboards/${code}`);
  },
  exportDashboard: async (code: string): ApiPromise<ExportDashboard> => {
    logger.debug(`exportDashboard: dashboardCode: ${code}`);
    return dataHubClient.get(`/dashboards/${code}/export`);
  },
  importDashboard: async (file: File): Promise<Cancellable<void>> => {
    logger.debug(`importDashboard`);
    return dataHubClient.postFile(`/dashboards/import`, () => '', file);
  },
  createPage: async (
    page: PageDto,
    dashboardCode: string,
  ): ApiPromise<void> => {
    logger.debug(`createPage for dashboard: dashboardCode: ${dashboardCode}`);
    return dataHubClient.post(`/dashboards/${dashboardCode}/pages`, page);
  },
  updatePage: async (
    page: PageDto,
    dashboardCode: string,
  ): ApiPromise<void> => {
    logger.debug(`updatePage for dashboard: dashboardCode: ${dashboardCode}`);
    return dataHubClient.patch(
      `/dashboards/${dashboardCode}/pages/${page.code}`,
      page,
    );
  },
  deletePage: async (
    dashboardCode: string,
    pageCode: string,
  ): ApiPromise<void> => {
    logger.debug(`deletePage with code: ${pageCode}`);
    return dataHubClient.delete(
      `/dashboards/${dashboardCode}/pages/${pageCode}`,
    );
  },
  loadWidgetDefinitions: async (): ApiPromise<WidgetDefinitionDto[]> => {
    logger.debug(`loadWidgetDefinitions`);
    return dataHubClient.get(`/widgetdefinitions`);
  },
  loadWidgetDefinition: async (
    type: string,
  ): ApiPromise<WidgetDefinitionDto> => {
    logger.debug(`loadWidgetDefinition with type ${type}`);
    return dataHubClient.get(`/widgetdefinitions/${type}`);
  },
  createWidgetDefinition: async (
    definition: WidgetDefinitionDto,
  ): ApiPromise<void> => {
    logger.debug(`createWidgetDefinition with type ${definition.type}`);
    return dataHubClient.post(`/widgetdefinitions`, definition);
  },
  updateWidgetDefinition: async (
    definition: WidgetDefinitionDto,
  ): ApiPromise<void> => {
    logger.debug(`updateWidgetDefinition with type ${definition.type}`);
    return dataHubClient.put(`/widgetdefinitions`, definition);
  },
  deleteWidgetDefinition: async (type: string): ApiPromise<void> => {
    logger.debug(`deleteWidgetDefinition with type ${type}`);
    return dataHubClient.delete(`/widgetdefinitions/${type}`);
  },
  createWidget: async (
    widget: WidgetDto,
    pageCode: string,
    dashboardCode: string,
  ): ApiPromise<void> => {
    logger.debug(
      `createWidget for dashboard: ${dashboardCode}, page: ${pageCode}`,
    );
    return dataHubClient.post(
      `/dashboards/${dashboardCode}/pages/${pageCode}/widgets`,
      widget,
    );
  },
  updateWidget: async (
    widget: WidgetDto,
    pageCode: string,
    dashboardCode: string,
  ): ApiPromise<void> => {
    logger.debug(`updateWidget code: ${widget.code}`);
    return dataHubClient.patch(
      `/dashboards/${dashboardCode}/pages/${pageCode}/widgets/${widget.code}`,
      widget,
    );
  },
  deleteWidget: async (
    widgetCode: string,
    pageCode: string,
    dashboardCode: string,
  ): ApiPromise<void> => {
    logger.debug(`deleteWidget code: ${widgetCode}`);
    return dataHubClient.delete(
      `/dashboards/${dashboardCode}/pages/${pageCode}/widgets/${widgetCode}`,
    );
  },
  startBatchUpload: async (
    ra: ResponsibleAuthority,
    type: BatchType,
    endpoint: string,
  ): ApiPromise<Batch> => {
    logger.debug(`startBatchUpload`);
    const client = endpoint.startsWith('/') ? apiHostClient : baseClient;
    return client.post(endpoint, {
      responsibleAuthority: ra.resourceCode,
      batchType: type.toLowerCase(),
    });
  },
  closeBatchUpload: async (
    batch: Batch,
    endpoint: string,
  ): ApiPromise<BatchUploadDetails[]> => {
    logger.debug(`closeBatchUpload`);
    const client = endpoint.startsWith('/') ? apiHostClient : baseClient;
    return client.post(`${endpoint}/${batch.batchCode}/close`);
  },
  loadResponsibleAuthorities: async (
    url: string,
  ): ApiPromise<ResponsibleAuthority[]> => {
    logger.debug(`loadResponsibleAuthorities: url: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  placeholder: async (): ApiPromise<unknown> => dataHubClient.get(''),
  uploadFile: async (
    batch: Batch,
    file: File,
    endpoint: string,
    progressHandler: (progress: number) => void,
  ): Promise<Cancellable<UploadResponse>> => {
    logger.debug(
      `Upload file for batchId:${batch.batchCode}, file:${
        file.name
      }, show progress:${!!progressHandler}`,
    );
    const client = endpoint.startsWith('/') ? apiHostClient : baseClient;
    return client.postFile(
      `${endpoint}/${batch.batchCode}`,
      progressHandler,
      file,
    );
  },
  secureDownload: async (url: string): ApiPromise<FileDownload> => {
    logger.debug(`secureDownload: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.download(url);
  },
  downloadBatch: async (
    batchCode: string,
    endpoint: string,
  ): ApiPromise<FileDownload> => {
    logger.debug(`downloadBatch: ${batchCode}`);
    const client = endpoint.startsWith('/') ? apiHostClient : baseClient;
    return client.download(`${endpoint}/${batchCode}`);
  },
  downloadBatchByType: async (
    batchCode: string,
    dataType: string,
    endpoint: string,
  ): ApiPromise<FileDownload> => {
    logger.debug(`downloadBatchByType: ${batchCode}`);
    const client = endpoint.startsWith('/') ? apiHostClient : baseClient;
    return client.download(`${endpoint}/${dataType}/${batchCode}`);
  },
  getBatchStatus: async (
    batchCode: string,
    endpoint: string,
  ): ApiPromise<Batch> => {
    logger.debug(`getBatchStatus: ${batchCode}`);
    const client = endpoint.startsWith('/') ? apiHostClient : baseClient;
    return client.get(`${endpoint}/${batchCode}/status`);
  },
  getDownloadUrl: async (url: string): ApiPromise<AttachmentUrl> => {
    logger.debug(`getDownloadUrl: ${url}`);
    return baseClient.get(url);
  },
  loadTableData: async (url: string): ApiPromise<Row[]> => {
    logger.debug(`loadTableData: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  loadResourceReportData: async (url: string): ApiPromise<Row[]> => {
    logger.debug(`loadResourceReportData: ${url}`);
    return baseClient.getCSV(url);
  },
  loadSimpleData: async (
    url: string,
  ): ApiPromise<Record<string, unknown>[]> => {
    logger.debug(`loadSimpleData: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  deleteResourceAttachment: async (url: string): ApiPromise<IdResponse> => {
    logger.debug(`deleteResourceAttachment: ${url}`);
    return baseClient.delete(url);
  },
  addMemberToGroup: async (url: string, body: string): ApiPromise<unknown> => {
    logger.debug(`addMemberToGroup: ${url}`);
    return baseClient.post(url, body);
  },
  removeUserFromGroup: async (
    url: string,
    body: string,
  ): ApiPromise<unknown> => {
    logger.debug(`removeUserFromGroup: ${url}`);
    return baseClient.post(url, body);
  },
  addRoleToUser: async (url: string, body: string): ApiPromise<unknown> => {
    logger.debug(`addRoleToUser: ${url}`);
    return baseClient.post(url, body);
  },
  removeRoleFromUser: async (
    url: string,
    body: string,
  ): ApiPromise<unknown> => {
    logger.debug(`removeRoleFromUser: ${url}`);
    return baseClient.post(url, body);
  },
  loadTenants: async (url: string): ApiPromise<Tenant[]> => {
    logger.debug(`load resource: ${url}`);
    return baseClient.get(url);
  },
  getGroups: async (dashboardCode: string): ApiPromise<GroupDetails[]> => {
    logger.debug(`getting groups`);
    return dataHubClient.get(`/${dashboardCode}/users/contact-groups`);
  },
  getGroup: async (
    dashboardCode: string,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`getting group`);
    return dataHubClient.get(
      `/${dashboardCode}/users/contact-groups/${groupId}`,
    );
  },
  updateGroupDetails: async (
    groupDetails: GroupDetails,
    dashboardCode: string,
  ): ApiPromise<Group> => {
    logger.debug(`updating group details ${groupDetails.id}`);
    return dataHubClient.post(
      `/${dashboardCode}/groups/${groupDetails.id}`,
      groupDetails,
    );
  },
  findUser: async (
    email: string,
    dashboardCode: string,
  ): ApiPromise<UserProfile> => {
    logger.debug(`finding user ${email}`);
    return dataHubClient.get(
      `/${dashboardCode}/users/find/${encodeURIComponent(email)}`,
    );
  },
  updateUserProfile: async (
    profile: UserProfile,
    dashboardCode: string,
  ): ApiPromise<void> => {
    logger.debug(`update user profile: ${profile.toString()}`);
    return dataHubClient.patch(`/${dashboardCode}/users`, profile);
  },
  addExistingUserToGroup: async (
    dashboardCode: string,
    user: User,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`adding user ${user.email} to group ${groupId}`);
    return dataHubClient.patch(
      `/${dashboardCode}/users/contact-groups/${groupId}`,
      user,
      'add-existing-user-to-contact-group',
    );
  },
  addNewUserToGroup: async (
    dashboardCode: string,
    user: User,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`adding new user ${user.email} to group ${groupId}`);
    return dataHubClient.patch(
      `/${dashboardCode}/users/contact-groups/${groupId}`,
      user,
      'add-new-user-to-contact-group',
    );
  },
  removeContactFromGroup: async (
    dashboardCode: string,
    contactId: string,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`removing contact ${contactId} from group ${groupId}`);
    return dataHubClient.delete(
      `/${dashboardCode}/users/contact-groups/${groupId}/contacts/${contactId}`,
    );
  },
  loadReportClients: async (url: string): ApiPromise<ReportClient[]> => {
    logger.debug(`loadReportClients: url: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  loadFilterCount: async (url: string): ApiPromise<FilterCount> => {
    logger.debug(`loadFilterCount: url: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  getApiKey: async (url: string): ApiPromise<string> => {
    logger.debug(`getApiKey for group: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  generateApiKey: async (url: string): ApiPromise<string> => {
    logger.debug(`generateApiKey for group: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.post(url);
  },
  getApiKeyIndices: async (url: string): ApiPromise<ApiKeyIndex[]> => {
    logger.debug(`getApiKeyIndices for: ${url}`);
    const client = url.startsWith('/') ? apiHostClient : baseClient;
    return client.get(url);
  },
  updateResource: async (resource: ResourceDto<unknown>): ApiPromise<void> => {
    logger.debug(`updateResource id: ${resource.id}`);
    return appEngClient.patch(`/resources/${resource.id}`, resource);
  },
  getUserResourcePermissions: async (
    id: string,
  ): ApiPromise<ListResourcePermissionDto> => {
    logger.debug(`list user permissions for resource with id ${id}`);
    return appEngClient.get(`/resources/${id}/users?limit=10000&data.expand=`);
  },
  getGroupResourcePermissions: async (
    id: string,
  ): ApiPromise<ListResourcePermissionDto> => {
    logger.debug(`list group permissions for resource with id ${id}`);
    return appEngClient.get(`/resources/${id}/groups?limit=10000&data.expand=`);
  },
  getResources: async (
    query: string,
  ): ApiPromise<ListResourceResponse<unknown>> => {
    logger.debug(`list resources filtered by ${query}`);
    return appEngClient.get(`/resources?${query}`);
  },
  getResource: async (id: string): ApiPromise<ResourceResponse<unknown>> => {
    logger.debug(`getResource id: ${id}`);
    return appEngClient.get(`/resources/${id}`);
  },
  getResourceAttachments: async (
    resourceId: string,
  ): ApiPromise<ListResourceAttachmentResponse> => {
    logger.debug(`getResourceAttachment for resourceId: ${resourceId}`);
    return appEngClient.get(`/resources/${resourceId}/attachments`);
  },
  assignResourcePermission: async (
    resourceId: string,
    partyId: string,
    partyType: PartyType,
    permissionType: PermissionType,
  ): ApiPromise<string> => {
    logger.debug(
      `assignResourcePermission ${permissionType} for ${partyId} to ${resourceId} `,
    );
    return appEngClient.post(`/permissions/assign`, {
      resourceId,
      partyId,
      partyType,
      permissionType,
    });
  },
  removeResourcePermission: async (
    resourceId: string,
    partyId: string,
    partyType: PartyType,
    permissionType: PermissionType,
  ): ApiPromise<string> => {
    logger.debug(
      `removeResourcePermission ${permissionType} for ${partyId} to ${resourceId} `,
    );
    return appEngClient.post(`/permissions/remove`, {
      resourceId,
      partyId,
      partyType,
      permissionType,
    });
  },
  getAppEngUser: async (id: string): ApiPromise<AppEngUser> => {
    logger.debug(`getAppEngUser id: ${id}`);
    return appEngClient.get(`/users/${id}`);
  },
  getAppEngGroup: async (id: string): ApiPromise<AppEngGroup> => {
    logger.debug(`getAppEngGroup id: ${id}`);
    return appEngClient.get(`/groups/${id}`);
  },
  getAppEngUsers: async (): ApiPromise<UserSearchResponse> => {
    logger.debug('getAppEngUsers');
    return appEngClient.get('/users');
  },
  getAppEngUsersByExternalId: async (
    externalId: string,
  ): ApiPromise<UserSearchResponse> => {
    logger.debug('getAppEngUsersByExternalId');
    return appEngClient.get(`/users?externalId=${externalId}`);
  },
  getAppEngGroups: async (): ApiPromise<GroupSearchResponse> => {
    logger.debug('getAppEngGroups');
    return appEngClient.get('/groups');
  },
  getApiData: async <T>(path: string): ApiPromise<T> => {
    logger.debug('getApiData');
    const client = path.startsWith('/') ? apiHostClient : baseClient;
    return client.get(path);
  },
  uploadFileToRedirect: async (
    requestPath: string,
    progressHandler: (progress: number) => void,
    file: File,
    body: Record<string, unknown>,
  ): Promise<Cancellable<string>> => {
    logger.debug('uploadFileToRedirect');
    const client = requestPath.startsWith('/') ? apiHostClient : baseClient;
    return client.postFileToRedirect(requestPath, progressHandler, file, body);
  },
});

export const API_CALL_NAMES: Array<ApiCallName> = Object.keys(
  clientInitialiser(
    {} as ReturnType<typeof connector>,
    {} as ReturnType<typeof connector>,
    {} as ReturnType<typeof connector>,
    {} as ReturnType<typeof connector>,
  ),
).filter(
  (name) =>
    name !== 'uploadFile' &&
    name !== 'importDashboard' &&
    name !== 'uploadFileToRedirect',
) as Array<ApiCallName>;

export const dataHubClient = (
  getConfigApiPromise: () => Promise<ApiConfig> = defaultGetConfig,
  tokenServiceFactory = AUTH_SERVICE_FACTORY,
): Promise<DataHubClient> =>
  getConfigApiPromise()
    .then(async ({ apiUrl, dataHubApiUrl, appEngineApiUrl }) => {
      const tokenService = await tokenServiceFactory();
      const apiHostClient = connector(apiUrl, '', tokenService);
      const mainClient = connector(`${dataHubApiUrl}`, '', tokenService);
      const baseClient = connector('', '', tokenService);
      const appEngClient = connector(appEngineApiUrl, '', tokenService);
      return clientInitialiser(
        apiHostClient,
        mainClient,
        baseClient,
        appEngClient,
      );
    })
    .catch((err) => {
      // logger.error('unable to create API client: ', err);
      throw err;
    });
