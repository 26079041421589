import axios, { AxiosError, AxiosResponse } from 'axios';
import logger from '@/logger';
import { RedirectPayload } from '@/api/types.dto';
import { UserTokenService, Cancellable } from './types';
import {
  BadGatewayError,
  ConnectorForbiddenError,
  DuplicateError,
  NotFoundError,
  REQUEST_CANCELLED_ERROR,
  UnauthorisedError,
} from './errors';
import {
  ApiError,
  UnexpectedApiError,
  LoadedData,
  ApiPromise,
  FileDownload,
  ApiResponse,
  hasData,
} from './data';

type ServerError = {
  exceptionType?: string;
  exceptionMessage?: string;
  errorCode?: string;
  description: string;
  message: string;
};

const errorHandler = <T>(error: AxiosError<ServerError>): ApiError => {
  if (axios.isAxiosError(error) && error.response) {
    // Marshal expected errors here
    if (error.response.status === 404 && error.response.data.description) {
      return NotFoundError(error.response.data.description);
    }
    if (
      error.response.status === 403 &&
      error.response.data.errorCode === 'CONNECTOR_FEEDBACK'
    ) {
      return ConnectorForbiddenError(error.response.data.description);
    }
    if (error.response.status === 502) {
      return BadGatewayError(error.response.data.message);
    }
    if (error.response.status === 401) {
      return UnauthorisedError(
        error.response.data.message || 'User not authorised to perform action',
      );
    }
    if (error.response.data.errorCode?.startsWith('DUPLICATE')) {
      return DuplicateError(error.response.data.description);
    }

    let message = '';
    if (error.response.data.message) {
      message = `${error.response.data.message}`;
    } else {
      message = `API responded with an error [${error.response.status}]${
        error.response.data.description
          ? `: ${error.response.data.description}`
          : ''
      }`;
    }
    logger.error(message);
    return UnexpectedApiError(message);
  }

  if (axios.isCancel(error)) {
    logger.error('Request cancelled');
    return REQUEST_CANCELLED_ERROR;
  }
  const message = `Unable to send request: ${error.message}`;
  logger.error(message);
  return UnexpectedApiError(message);
};

const successHandler = <ResponseT>(
  response: AxiosResponse<ResponseT>,
): LoadedData<ResponseT> => LoadedData(response.data);

const path = (baseUrl: string, basePath: string, requestPath: string) =>
  `${baseUrl}${basePath}${requestPath}`;

const headers = (authToken?: string) => ({
  ...(authToken
    ? {
        Authorization: `Bearer ${authToken}`,
      }
    : {}),
});

const dataHubContentType = (contentSuffix?: string) =>
  `application/${contentSuffix ? `datahub-${contentSuffix}+` : ''}json`;

const requestWithBody = <ResponseT, RequestBody>(
  method: 'post' | 'patch' | 'put',
  tokenService: UserTokenService,
  baseUrl: string,
  basePath: string,
  requestPath: string,
  body: RequestBody,
  contentSuffix?: string,
): ApiPromise<ResponseT> =>
  tokenService.getAccessToken().then((authToken?: string) =>
    axios
      .request<ResponseT>({
        url: path(baseUrl, basePath, requestPath),
        method,
        data: body,
        headers: {
          ...headers(authToken),
          ...(body !== undefined
            ? {
                'Content-Type': dataHubContentType(contentSuffix),
              }
            : {}),
        },
      })
      .then(successHandler, errorHandler),
  );

export const downloadWithToken = (
  url: string,
  token?: string,
): ApiPromise<FileDownload> =>
  axios
    .request<ArrayBuffer>({
      method: 'get',
      responseType: 'arraybuffer',
      withCredentials: true,
      url,
      headers: {
        ...headers(token),
      },
    })
    .then((response) => {
      let fileName;
      try {
        fileName = response.request
          .getResponseHeader('content-disposition')
          .split(';')
          .map((header: string) => header.trim())
          .find((header: string) => header.startsWith('filename='))
          .split('=')[1]
          .replaceAll('"', '');
      } catch (e) {
        logger.error(e);
      }
      return LoadedData({ buffer: response.data, fileName });
    }, errorHandler);

export default (
  baseUrl: string,
  basePath: string,
  tokenService: UserTokenService,
) => ({
  get: <ResponseT>(
    requestPath: string,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    tokenService
      .getAccessToken()
      .then((authToken?: string) =>
        axios
          .request<ResponseT>({
            method: 'get',
            withCredentials: true,
            url: path(baseUrl, basePath, requestPath),
            headers: {
              ...headers(authToken),
              Accept: dataHubContentType(contentSuffix),
            },
          })
          .then(successHandler, errorHandler),
      )
      .catch((e) => {
        logger.error(e);
        throw e;
      }),
  getCSV: <ResponseT>(requestPath: string): ApiPromise<ResponseT> =>
    tokenService.getAccessToken().then((authToken?: string) =>
      axios
        .request<ResponseT>({
          method: 'get',
          withCredentials: true,
          url: path(baseUrl, basePath, requestPath),
          headers: {
            ...headers(authToken),
            Accept: 'text/csv',
          },
        })
        .then(successHandler, errorHandler),
    ),
  delete: <ResponseT>(requestPath: string): ApiPromise<ResponseT> =>
    tokenService.getAccessToken().then((authToken?: string) =>
      axios
        .request<ResponseT>({
          method: 'delete',
          url: path(baseUrl, basePath, requestPath),
          headers: {
            ...headers(authToken),
          },
        })
        .then(successHandler, errorHandler),
    ),

  post: <ResponseT, RequestBody>(
    requestPath: string,
    body?: RequestBody,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    requestWithBody(
      'post',
      tokenService,
      baseUrl,
      basePath,
      requestPath,
      body,
      contentSuffix,
    ),

  put: <ResponseT, RequestBody>(
    requestPath: string,
    body?: RequestBody,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    requestWithBody(
      'put',
      tokenService,
      baseUrl,
      basePath,
      requestPath,
      body,
      contentSuffix,
    ),

  postFile: <ResponseT, RequestBody>(
    requestPath: string,
    progressHandler: (progress: number) => void,
    file: File,
  ): Promise<Cancellable<ResponseT>> =>
    tokenService.getAccessToken().then((authToken?: string) => {
      const source = axios.CancelToken.source();
      const formData = new FormData();
      formData.append('file', file);
      return {
        cancel: () => source.cancel(),
        promise: axios
          .post(path(baseUrl, basePath, requestPath), formData, {
            onUploadProgress: (progress: ProgressEvent) =>
              progressHandler(progress.loaded),
            cancelToken: source.token,
            headers: {
              ...headers(authToken),
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(successHandler, errorHandler),
      };
    }),

  postFileToRedirect: <ResponseT, RequestBody>(
    requestPath: string,
    progressHandler: (progress: number) => void,
    file: File,
    body: Record<string, unknown>,
  ): Promise<Cancellable<string>> =>
    tokenService
      .getAccessToken()
      .then((authToken?: string) =>
        axios.request<RedirectPayload>({
          url: path(baseUrl, basePath, requestPath),
          method: 'post',
          headers: {
            ...headers(authToken),
          },
          data: body,
        }),
      )
      .then(
        (r: AxiosResponse<RedirectPayload>) => successHandler(r),
        errorHandler,
      )
      .then((payload: ApiResponse<RedirectPayload>) => {
        if (!hasData(payload)) {
          throw new Error(
            `Invalid payload during upload ${
              'message' in payload ? `: ${payload.message}` : ''
            }`,
          );
        }
        const source = axios.CancelToken.source();
        return {
          cancel: () => source.cancel(),
          promise: axios
            .put(payload.data.redirectUrl, file, {
              onUploadProgress: (progress: ProgressEvent) =>
                progressHandler(progress.loaded),
              cancelToken: source.token,
            })
            .then(successHandler)
            .then(() => LoadedData(payload.data.fileId), errorHandler),
        };
      }),

  patch: <ResponseT, RequestBody>(
    requestPath: string,
    body: RequestBody,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    requestWithBody(
      'patch',
      tokenService,
      baseUrl,
      basePath,
      requestPath,
      body,
      contentSuffix,
    ),

  download: (requestPath: string): ApiPromise<FileDownload> =>
    tokenService
      .getAccessToken()
      .then((authToken?: string) =>
        downloadWithToken(path(baseUrl, basePath, requestPath), authToken),
      ),
});
