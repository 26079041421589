
import Vue from 'vue';
import FormButton from '@/components/ripple/form/FormButton.vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import { Group, GroupDetails } from '@/models/group.model';
import {
  ApiErrorType,
  ApiResponse,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
} from '@/api/data';
import FieldStrings from '@/components/ripple/form/FieldStrings.vue';

Vue.component('field-done-button', FormButton);
Vue.component('field-strings', FieldStrings);

type Data = {
  model?: GroupDetails;
  working: boolean;
  response: { response?: { message: string; status: string } };
};
type Methods = {
  onUpdate: () => void;
  showFormMessage: (message: string, status: string) => void;
  tidyEmailAddresses: (addresses?: string[]) => string[];
};
type Computed = {
  group?: GroupDetails;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  formData: object;
};
type Props = { groupId: string; code: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'GroupDetailsForm',
  props: { groupId: String, code: String },
  components: { RplIcon, RplForm },
  data() {
    return {
      model: undefined,
      working: false,
      response: {},
      captureUserDetails: false,
    };
  },
  computed: {
    isLoading() {
      return isLoading(this.$store.state.group.groupFromApi);
    },
    isError() {
      return isApiError(this.$store.state.group.groupFromApi);
    },
    group() {
      const group: Group = getData(this.$store.state.group.groupFromApi);
      if (group) {
        const { contacts, ...details } = group;
        return details;
      }
      return undefined;
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.group.groupFromApi) ||
        'Error loading group'
      );
    },
    formData() {
      return {
        model: this.model,
        tag: 'rpl-fieldset',
        schema: {
          groups: [
            {
              legend: 'Group details',
              fields: [
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Group name',
                  model: 'name',
                  disabled: true,
                  required: true,
                  max: 2048,
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Phone number',
                  model: 'phoneNumber',
                  max: 2048,
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Street address',
                  model: 'streetAddress',
                  max: 2048,
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Suburb',
                  model: 'suburb',
                  max: 2048,
                },
                {
                  type: 'rplselect',
                  label: 'State',
                  model: 'state',
                  values: [
                    { name: 'VIC', id: 'VIC' },
                    { name: 'ACT', id: 'ACT' },
                    { name: 'NSW', id: 'NSW' },
                    { name: 'NT', id: 'NT' },
                    { name: 'QLD', id: 'QLD' },
                    { name: 'TAS', id: 'TAS' },
                    { name: 'WA', id: 'WA' },
                  ],
                  styleClasses: 'form-group--col-two',
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Postcode',
                  model: 'postcode',
                  styleClasses: 'form-group--col-two',
                },
              ],
            },
            {
              legend: 'Email configuration',
              inline: true,
              fields: [
                {
                  type: 'input',
                  inputType: 'email',
                  label: 'Shared email address',
                  model: 'email',
                  required: true,
                  max: 2048,
                  validator: VueFormGenerator.validators.email,
                },
                {
                  type: 'strings',
                  inputType: 'email',
                  label: 'To',
                  model: 'toEmailAddresses',
                  styleClasses: 'form-group--col-two',
                },
                {
                  type: 'strings',
                  inputType: 'email',
                  label: 'Cc',
                  model: 'ccEmailAddresses',
                  styleClasses: 'form-group--col-two',
                },
              ],
            },
            {
              inline: true,
              fields: [
                {
                  type: 'done-button',
                  buttonText: 'Cancel',
                  clickHandler: () =>
                    this.$router.push({
                      name: 'group',
                      params: { id: this.groupId },
                    }),
                  styleClasses: 'form-group--inline',
                },
                {
                  type: 'rplsubmitloader',
                  buttonText: 'Update',
                  loading: this.working,
                  autoUpdate: true,
                  styleClasses: 'form-group--inline',
                },
              ],
            },
          ],
        },
        formState: this.response,
        formOptions: {
          validateAfterChanged: false,
        },
      };
    },
  },
  methods: {
    onUpdate() {
      this.working = true;
      this.$store
        .dispatch('updateGroupDetails', {
          dashboardCode: this.code,
          groupDetails: {
            ...this.model,
            toEmailAddresses: this.tidyEmailAddresses(
              this.model?.toEmailAddresses,
            ),
            ccEmailAddresses: this.tidyEmailAddresses(
              this.model?.ccEmailAddresses,
            ),
          },
        })
        .then((response: ApiResponse<GroupDetails>) => {
          if (isApiError(response)) {
            this.showFormMessage(
              response.type === ApiErrorType.Duplicate
                ? `A group with name ${this.model?.name} already exists`
                : response.message,
              'error',
            );
          } else {
            this.$router.push({
              name: 'group',
              params: { id: this.groupId },
            });
          }
        })
        .finally(() => {
          this.working = false;
        });
    },
    showFormMessage(message, status) {
      this.response = { response: { message, status } };
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    tidyEmailAddresses(addresses) {
      return addresses?.map((a) => a.trim()).filter((a) => a !== '') || [];
    },
  },
  watch: {
    group() {
      if (this.group) {
        this.model = { ...this.group };
      }
    },
  },
  created() {
    if (
      !hasData(
        this.$store.state.group.groupFromApi || this.group?.id !== this.groupId,
      )
    ) {
      this.$store.dispatch('getGroup', {
        dashboardCode: this.code,
        groupId: this.groupId,
      });
    } else if (this.group) {
      this.model = { ...this.group };
    }
    this.$store.commit('setBreadcrumbs', [
      {
        text: 'Manage groups',
        url: this.$router.resolve({
          name: 'group',
          params: { id: this.groupId },
        }).href,
      },
      { text: 'Update group details' },
    ]);
    this.$store.commit('setHeaderText', 'Update group details');
    this.$store.commit('setSubText', '');
  },
});
