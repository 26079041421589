import { Page } from '@/models/dashboard.model';
import { WidgetPosition } from '@/models/admin.model';

export const hasGridLayout = (page?: Page) =>
  !!page?.numCols && !!page?.numRows;

export const layoutStyle = (numCols?: number, numRows?: number) => ({
  gridTemplateColumns: `repeat(${numCols || 1}, minmax(0, 1fr)`,
  gridTemplateRows: `repeat(${numRows || 1}, minmax(5rem, auto))`,
});

export const pageLayoutStyle = (page: Page) =>
  layoutStyle(page.numCols, page.numRows);

export const widgetLayoutStyle = (position: WidgetPosition) => {
  if (
    position.startCol &&
    position.endCol &&
    position.startRow &&
    position.endRow
  ) {
    return {
      gridColumnStart: position.startCol,
      gridColumnEnd: position.endCol,
      gridRowStart: position.startRow,
      gridRowEnd: position.endRow,
    };
  }
  return undefined;
};
