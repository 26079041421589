
import { RplHeroBanner } from '@dpc-sdp/ripple-hero-banner';
import Vue from 'vue';
import { defaultBannerBackground } from '@/themes';

type Data = {};
interface Computed {
  headerText: string;
  subText: string;
  bannerBackground: SVGElement;
}
type Methods = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HeroBanner',
  components: {
    RplHeroBanner,
  },
  computed: {
    headerText() {
      return this.$store.getters.headerText;
    },
    subText() {
      return this.$store.getters.subText;
    },
    bannerBackground() {
      return defaultBannerBackground;
    },
  },
  props: { theme: String, disabled: Boolean, inverse: Boolean },
});
