import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import auth from '@/api/auth';
import logger from '@/logger';
import { REDIRECT_ROUTE_KEY } from '@/config/constants';
import { Routes } from './routes';
import { paths } from './paths';

Vue.use(VueRouter);

type Position = { x: number; y: number };

type PositionResult =
  | Position
  | void
  | { selector: string; offset?: Position; behavior?: ScrollBehavior };

type ScrollBehaviorHandler = (
  to: Route,
  from: Route,
  savedPosition: Position | void,
) => PositionResult | Promise<PositionResult> | undefined | null;

const scrollBehavior: ScrollBehaviorHandler = () => ({
  x: 0,
  y: 0,
  behavior: 'smooth',
});
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: Routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  if (!to.meta?.requireAuth) {
    next();
    return Promise.resolve();
  }

  // special handling for pages accessed under public dashboards
  if (to.name === 'page' && from.meta?.pagePathName) {
    return router.push({ ...to, name: from.meta.pagePathName });
  }

  return auth()
    .then((authService) =>
      authService.isAuthenticated().then((authenticated) => {
        if (!authenticated) {
          logger.error(
            `Trying to access '${to.path}', but we're not logged in.`,
          );
          sessionStorage.setItem(REDIRECT_ROUTE_KEY, to.fullPath);
          next(paths.login());
        } else {
          next();
        }
      }),
    )
    .catch((err) => {
      next(err);
    });
});

export default router;
