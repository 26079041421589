
import RplIcon from '@dpc-sdp/ripple-icon';
import Button from '@/components/ripple/Button.vue';
import { defineComponent } from 'vue';
import _isString from 'lodash/isString';

export default defineComponent({
  name: 'Modal',
  components: { Button, RplIcon },
  props: {
    title: { type: String, required: true },
    errorMessage: String,
    confirmButtonText: { type: String },
    showConfirmButton: { type: Boolean, default: true },
    cancelButtonText: String,
  },
  methods: {
    backgroundClick(event: Event) {
      event.stopPropagation();
    },
    onTab() {
      const cancelButton = this.$refs.cancelButton as HTMLButtonElement;
      if (document.activeElement === cancelButton) {
        const confirmButton = this.$refs.confirmButton as HTMLButtonElement;
        this.$nextTick(() => confirmButton.focus());
      } else {
        this.$nextTick(() => cancelButton.focus());
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      this.$emit('confirm');
    },
  },
  computed: {
    hasErrorMessage() {
      return (
        _isString(this.errorMessage) && this.errorMessage.trim().length > 0
      );
    },
  },
  mounted() {
    const cancelButton = this.$refs.cancelButton as HTMLButtonElement;
    if (!this.hasErrorMessage) {
      this.$nextTick(() => cancelButton.focus());
    }
  },
});
