import { ActionContext, Module } from 'vuex';
import { PageState } from '@/store/modules/types';
import { RootState } from '../types';

const pagemodule: Module<PageState, RootState> = {
  state: () => ({
    message: undefined,
  }),
  mutations: {
    setPageMessage(state, message: string) {
      state.message = message;
    },
    clearPageMessage(state) {
      state.message = undefined;
    },
  },
  actions: {
    showPageMessage(
      { commit }: ActionContext<PageState, RootState>,
      message: string,
    ) {
      commit('setPageMessage', message);
      setTimeout(() => commit('clearPageMessage'), 3000);
    },
  },
};

export default pagemodule;
