<template>
  <div>Logging in...</div>
</template>

<script>
import authServiceFactory from '@/api/auth';

export default {
  name: 'BeginLogin',
  created() {
    const { dashboard } = this.$route.query;
    let redirect = '';
    if (dashboard) {
      redirect = this.$router.resolve({
        name: 'dashboard',
        params: { code: dashboard },
      }).route.fullPath;
    }
    authServiceFactory().then((auth) => auth.redirectToLogin(redirect));
  },
};
</script>
