
import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { RowViewModel } from '@/models/table.model';
import getConfig from '@/config';
import _template from 'lodash/template';

type Data = {
  loading: boolean;
};
type Computed = unknown;
interface Methods {
  onClick: (event: Event) => void;
}
type Props = {
  rowViewModel: RowViewModel;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RemoveUserFromGroupAction',
  components: { RplIcon },
  data() {
    return {
      loading: false,
    };
  },
  props: { rowViewModel: Object },
  methods: {
    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      this.loading = true;
      getConfig().then((config) => {
        const url = _template(
          this.rowViewModel.row.config.removeUserFromGroupURL,
        )({
          query: this.$route.query,
          config,
          row: this.rowViewModel.row,
        });

        const userId = _template(
          this.rowViewModel.row.config?.removeUserFromGroupUserId ??
            '<%= row.id %>',
        )({
          query: this.$route.query,
          config,
          row: this.rowViewModel.row,
        });

        const body = { userId };
        this.$store
          .dispatch('removeUserFromGroup', { url, body })
          .then(() => {
            if (this.rowViewModel.callBackFunction !== undefined) {
              this.rowViewModel.callBackFunction({
                action: 'refresh',
                rowViewModel: this.rowViewModel,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
});
