import { ActionContext, Module } from 'vuex';
import { getData, isApiError } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';
import {
  Access,
  PartyType,
  PermissionType,
  ResourceDto,
  ResourcePermission,
  ResourcePermissionDto,
} from '@/models/resource.model';
import { ResourceState } from './types';
import { RootState } from '../types';

const resourcemodule: Module<ResourceState, RootState> = {
  state: () => ({}),
  actions: {
    getResources(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      query: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getResources',
        (client) => client.getResources(query),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    getResource(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      id: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getResource',
        (client) => client.getResource(id),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    getAppEngUser(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      id: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getAppEngUser',
        (client) => client.getAppEngUser(id),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    getAppEngGroup(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      id: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getAppEngGroup',
        (client) => client.getAppEngGroup(id),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    getAppEngUsers({
      commit,
      rootState,
      rootGetters,
    }: ActionContext<ResourceState, RootState>) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getAppEngUsers',
        (client) => client.getAppEngUsers(),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response).results;
      });
    },
    getAppEngUsersByExternalId(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      id: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getAppEngUsersByExternalId',
        (client) => client.getAppEngUsersByExternalId(id),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response).results;
      });
    },
    getAppEngGroups({
      commit,
      rootState,
      rootGetters,
    }: ActionContext<ResourceState, RootState>) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getAppEngGroups',
        (client) => client.getAppEngGroups(),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response).results;
      });
    },
    updateResource(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      resource: ResourceDto<unknown>,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateResource',
        (client) => client.updateResource(resource),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    async getResourcePermissions(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      resourceId: string,
    ) {
      const userResourcePermissionsResponse = await recordApiCall(
        { rootState, rootGetters, commit },
        'getUserResourcePermissions',
        (client) => client.getUserResourcePermissions(resourceId),
      );
      const groupResourcePermissionsResponse = await recordApiCall(
        { rootState, rootGetters, commit },
        'getGroupResourcePermissions',
        (client) => client.getGroupResourcePermissions(resourceId),
      );
      const convertToResourcePermissions = (
        rp: ResourcePermissionDto,
        partyType: PartyType,
      ) => {
        const access: Access[] = [];
        rp.permissions.inheritance.forEach((pi) => {
          access.push({
            type: pi.permission,
            inheritance: pi.type,
          });
        });
        return {
          access,
          partyId: rp.id,
          partyType,
        };
      };
      let permissions: ResourcePermission[];
      if (isApiError(userResourcePermissionsResponse)) {
        throw new Error(userResourcePermissionsResponse.message);
      }
      permissions = getData(userResourcePermissionsResponse).results.map((rp) =>
        convertToResourcePermissions(rp, 'USER'),
      );

      if (isApiError(groupResourcePermissionsResponse)) {
        throw new Error(groupResourcePermissionsResponse.message);
      }
      permissions = permissions.concat(
        getData(groupResourcePermissionsResponse).results.map((rp) =>
          convertToResourcePermissions(rp, 'GROUP'),
        ),
      );
      return permissions;
    },
    assignResourcePermission(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      args: {
        resourceId: string;
        partyId: string;
        partyType: PartyType;
        permissionType: PermissionType;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'assignResourcePermission',
        (client) =>
          client.assignResourcePermission(
            args.resourceId,
            args.partyId,
            args.partyType,
            args.permissionType,
          ),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
    removeResourcePermission(
      {
        commit,
        rootState,
        rootGetters,
      }: ActionContext<ResourceState, RootState>,
      args: {
        resourceId: string;
        partyId: string;
        partyType: PartyType;
        permissionType: PermissionType;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeResourcePermission',
        (client) =>
          client.removeResourcePermission(
            args.resourceId,
            args.partyId,
            args.partyType,
            args.permissionType,
          ),
      ).then((response) => {
        if (isApiError(response)) {
          throw new Error(response.message);
        }
        return getData(response);
      });
    },
  },
};

export default resourcemodule;
